import { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  // Button,
} from '@mui/material';
import moment from 'moment';
import styles from './CouponsTable.module.scss';
import {
  fetchCoupons,
  getCoupons,
  selectIsLoading,
} from 'src/redux/slices/coupons/couponsSlice';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import { Coupon } from 'src/redux/slices/coupons/types';
// import { EditCouponsModal } from '../EditModal/EditModal';

const CouponsTable = () => {
  const dispatch = useAppDispatch();
  const coupons = useAppSelector(getCoupons);
  const isLoading = useAppSelector(selectIsLoading);
  // const [isOpenModal, setIsOpenModal] = useState(false);
  // const [selectedCoupon, setSelectedCoupon] = useState<Coupon | null>(null); // Track selected coupon
  // const [showExpiredOnly, setShowExpiredOnly] = useState(false); // State to toggle filter

  useEffect(() => {
    dispatch(fetchCoupons());
  }, [dispatch]);

  const formatCode = (code: string) => {
    return code.match(/.{1,3}/g)?.join('-') || code;
  };

  // const filteredCoupons = showExpiredOnly
  //   ? coupons.filter((coupon: Coupon) =>
  //       moment(coupon.endDate).isBefore(moment())
  //     )
  //   : coupons;

  // const handleOpenModal = (coupon: Coupon) => {
  //   setSelectedCoupon(coupon); // Set selected coupon
  //   setIsOpenModal(true); // Open modal
  // };

  return (
    <TableContainer component={Paper} className={styles.tableContainer}>
      <Typography variant="h6" className={styles.tableTitle}>
        Coupons
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        {/* <Button
          variant="contained"
          color={showExpiredOnly ? 'secondary' : 'primary'}
          onClick={() => setShowExpiredOnly(!showExpiredOnly)}
        >
          {showExpiredOnly ? 'Show All Coupons' : 'Show Expired Coupons'}
        </Button> */}
      </div>
      {isLoading ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Table aria-label="coupons table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Code</TableCell>
              <TableCell align="center">Client</TableCell>
              <TableCell align="center">Max Users</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Start Date</TableCell>
              <TableCell align="center">End Date</TableCell>
              {/* <TableCell align="center">Actions</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons.map((coupon: Coupon) => (
              <TableRow key={coupon._id} className={styles.tableRow}>
                <TableCell align="center">{formatCode(coupon.code)}</TableCell>
                <TableCell align="center">{coupon.client}</TableCell>
                <TableCell align="center">{coupon.max_users}</TableCell>
                <TableCell align="center">{coupon.status}</TableCell>
                <TableCell align="center">
                  {moment(coupon.startDate).format('DD.MM.YYYY')}
                </TableCell>
                <TableCell align="center">
                  {moment(coupon.endDate).format('DD.MM.YYYY')}
                </TableCell>
                {/* <TableCell align="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenModal(coupon)}
                  >
                    Edit
                  </Button>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {/* {selectedCoupon && (
        <EditCouponsModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          coupon={selectedCoupon} // Pass selected coupon data
        />
      )} */}
    </TableContainer>
  );
};

export default CouponsTable;

import React from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@mui/material';
import MaterialTextField from 'src/components/material/textField';

import styles from '../SignUp.module.scss';
import { schema, IFormInput } from '../const';
// import { useNavigate } from 'react-router-dom';
// import { PathRoutes } from 'src/3const/Routes/PathRoutes';

interface SecondStepProps {
  onSubmit: SubmitHandler<IFormInput>;
  error: string;
}

export const SecondStep: React.FC<SecondStepProps> = ({ onSubmit, error }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: zodResolver(schema),
  });

  // const navigate = useNavigate();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Controller
        name="confirmationCode"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <MaterialTextField
            label="Confirmation Code"
            variant="outlined"
            error={!!errors.confirmationCode}
            helperText={
              errors.confirmationCode ? errors.confirmationCode.message : error
            }
            {...field}
            color="primary"
          />
        )}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: '47px', mb: 2, textTransform: 'none', fontSize: '18px' }}
        size="large"
        // onClick={() => navigate(PathRoutes.CreateProfileRoute)}
      >
        Confirm
      </Button>
    </form>
  );
};

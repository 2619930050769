import { Button } from '@mui/material';
import styles from './TotalDue.module.scss';
import { FC } from 'react';

interface TotalDueProps {
  price: number | undefined;
}

export const TotalDue: FC<TotalDueProps> = ({ price }) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>Total due</div>
        <div className={styles.title}>{price} $</div>
      </div>
      <div className={styles.cancelInfo}>
        <div>
          <div className={styles.cancelTitle}>
            Cancel any time subscribe with confidence with
          </div>
          <div className={styles.cancelDesc}>
            Dunbar's Circle Trust Guarantee
          </div>
        </div>
        <div className={styles.letter}>D</div>
      </div>
      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 2, mb: 0, textTransform: 'none', fontSize: '16px' }}
        size="medium"
        fullWidth
      >
        Subscribe
      </Button>
    </div>
  );
};

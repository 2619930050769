import { FC } from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@mui/material';
import MaterialTextField from 'src/components/material/textField';
import { schema, IFormInput } from '../const';
import styles from '../SignUp.module.scss';

interface FirstStepProps {
  onSubmit: SubmitHandler<IFormInput>;
  error: string;
}

export const FirstStep: FC<FirstStepProps> = ({ onSubmit, error }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: zodResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Controller
        name="phoneNumber"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <MaterialTextField
            isPhoneNumber
            label="Phone Number"
            variant="outlined"
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
            {...field}
            color="primary"
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <MaterialTextField
            label="Password"
            variant="outlined"
            isPassword
            error={!!errors.password}
            helperText={errors.password ? errors.password.message : error}
            {...field}
            color="success"
          />
        )}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: '47px', mb: 2, textTransform: 'none', fontSize: '18px' }}
        size="large"
      >
        Continue
      </Button>
    </form>
  );
};

import Habits from '../../Habits/Habits';
import { Button } from '@mui/material';
import { MuiModal } from 'src/components/material/MuiModal';
import { FC } from 'react';
import { UploadVideo } from '../../Video/Video';

interface CreatePostProps {
  isOpenModal: boolean;
  setIsOpenModal: (open: boolean) => void;
}

export const CreatePost: FC<CreatePostProps> = ({
  isOpenModal,
  setIsOpenModal,
}) => {
  // const [habbits, setHabbits] = useState([]);
  // const handleHabitsChange = useCallback(
  //   (newHabits: { name: string; category: string }[]) => {
  //     setHabbits((prevData) => ({
  //       ...prevData,
  //       newHabits,
  //     }));
  //   },
  //   []
  // );

  return (
    <MuiModal open={isOpenModal} setOpen={setIsOpenModal} width={300}>
      <UploadVideo
        defaultVideo={''}
        onUpdate={(fileId) => {
          console.log('Video uploaded with ID:', fileId);
        }}
        helperText="Upload a video (max 60 seconds)"
      />
      <Habits
        description="Describe the activities that you want your Members to attempt - usually a reflection of what is covered in the video."
        onHabitsChange={() => console.log('123')}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, textTransform: 'none', fontSize: '18px' }}
        size="large"
      >
        Post Challenge
      </Button>
    </MuiModal>
  );
};

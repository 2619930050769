import { FC } from 'react';
import { Category } from 'src/components/common/Category/Category';
import styles from './ChooseCategory.module.scss';

interface ChooseCategoryProps {
  selectedCategory: string;
  onCategoryChange: (category: string) => void;
}

export const ChooseCategory: FC<ChooseCategoryProps> = ({
  selectedCategory,
  onCategoryChange,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Category</div>
      <div className={styles.description}>
        Pick a category under which the Habit falls. If you would like to
        suggest a new category, please <span>contact us.</span>
      </div>
      <Category
        selectedCategory={selectedCategory}
        onCategoryChange={onCategoryChange}
      />
    </div>
  );
};

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { adminPanelApi } from 'src/api/adminPanel';
import { Coupon } from './types';
import { RootState } from 'src/redux/store/store';

// Define the initial state for the slice
interface CouponsState {
  coupons: Coupon[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: CouponsState = {
  coupons: [],
  status: 'idle',
  error: null,
};

// Thunk for fetching coupons
export const fetchCoupons = createAsyncThunk(
  'coupons/fetchCoupons',
  async () => {
    const response = await adminPanelApi.getCoupons();
    return response.data.coupons;
  }
);

// Thunk for creating a new coupon
export const createCoupon = createAsyncThunk(
  'coupons/createCoupon',
  async ({
    client,
    maxUses,
    startDate,
    endDate,
  }: {
    client: string;
    maxUses: number;
    startDate: string;
    endDate: string;
  }) => {
    const response = await adminPanelApi.createCoupon(
      client,
      maxUses,
      startDate,
      endDate
    );
    return response.data.coupon;
  }
);

const couponsSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchCoupons
      .addCase(fetchCoupons.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchCoupons.fulfilled,
        (state, action: PayloadAction<Coupon[]>) => {
          state.status = 'succeeded';
          state.coupons = action.payload;
        }
      )
      .addCase(fetchCoupons.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch coupons';
      })
      // Handle createCoupon
      .addCase(
        createCoupon.fulfilled,
        (state, action: PayloadAction<Coupon>) => {
          state.coupons.push(action.payload);
        }
      );
  },
});

export const getCoupons = (state: RootState): Coupon[] => state.coupons.coupons;
export const selectIsLoading = (state: RootState): boolean =>
  state.coupons.status === 'loading';

export default couponsSlice.reducer;

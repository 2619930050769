import api from './baseApi';

export const postsApi = {
  getPostsForCircle: (id: string) => {
    return api.get(`posts/?tribe_id=${id}`);
  },
  getSelectedPost: (id: string) => {
    return api.get(`posts/${id}`);
  },

  getAllPosts: (
    params: {
      page?: number;
      pageSize?: number;
      tribe_id?: string | null;
      videoRequired?: boolean;
    } = {}
  ) => {
    const {
      page = 1,
      pageSize = 10,
      tribe_id = null,
      videoRequired = true,
    } = params;

    const queryParams = new URLSearchParams();
    queryParams.append('page', page.toString());
    queryParams.append('pageSize', pageSize.toString());
    if (tribe_id) queryParams.append('tribe_id', tribe_id);
    queryParams.append('videoRequired', videoRequired.toString());

    return api.get(`posts/?${queryParams.toString()}`);
  },

  getCommentsForSelectedPost: (postId: string) => {
    return api.get(`posts/${postId}/comments`);
  },
};

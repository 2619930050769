import { useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { PathRoutes } from 'src/const/Routes/PathRoutes';

export const Header = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div
          className={styles.arrow}
          onClick={() => navigate(PathRoutes.HomeRoute)}
        >
          <KeyboardBackspaceIcon sx={{ color: 'black' }} />
        </div>
        <div className={styles.title}>Edit circle</div>
      </div>
      <div className={styles.desc}>
        Everything below can be changed at any time later, too.
      </div>
    </div>
  );
};

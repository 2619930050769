import screenOne from 'src/assets/images/howToPage/Picture30.png';

import styles from './LogIn.module.scss';

export const LogIn = () => {
  return (
    <div>
      <div className={styles.blockTitle}>9- Sign in / Log in</div>
      <div className={styles.content}>
        <img src={screenOne} alt="" />
        <div>
          <ul className={styles.list}>
            <li>9.1 select your country code</li>
            <li>9.2 enter your phone number</li>
            <li>9.3 enter your password</li>
            <li>9.4 Click Sign In</li>
          </ul>
          <div className={styles.text}>
            If you forgot your password, follow the steps here.
          </div>
          <div className={styles.text}>
            To create an account, follow the steps here.
          </div>
        </div>
      </div>
    </div>
  );
};

import { SectionComponent } from '../SectionCompnent/SectionCompnent';
import {
  MINI_AUCTION,
  SUB,
  AUCTION,
  GRAND_AUCTION,
  WAITLIST,
  CONSTANT_SUB_FEE,
  END_SUB,
  TLDR,
} from '../const';
import styles from './Subscription.module.scss';

export const Subscription = () => {
  return (
    <div className={styles.container} id="info-sub">
      <SectionComponent title={SUB.title} description={SUB.description} />
      <div id="join-a-circle-auction">
        <SectionComponent
          isAuction
          title={AUCTION.title}
          description={AUCTION.description}
        />
      </div>
      <div id="join-a-circle-grand-auction">
        <SectionComponent
          isSmallTitle
          title={GRAND_AUCTION.title}
          description={GRAND_AUCTION.description}
        />
      </div>
      <div id="join-a-circle-mini-auction">
        <SectionComponent
          isSmallTitle
          title={MINI_AUCTION.title}
          description={MINI_AUCTION.description}
        />
      </div>
      <br />
      <br />
      <div id="join-a-circle-waitlist">
        <SectionComponent
          isSmallTitle
          title={WAITLIST.title}
          description={WAITLIST.description}
        />
      </div>
      <div id="join-a-circle-subscription-fee">
        <SectionComponent
          isSmallTitle
          title={CONSTANT_SUB_FEE.title}
          description={CONSTANT_SUB_FEE.description}
        />
      </div>
      <div id="join-a-circle-ending-sub">
        <SectionComponent
          isSmallTitle
          title={END_SUB.title}
          description={END_SUB.description}
        />
      </div>
      <div id="join-a-circle-tldr">
        <SectionComponent
          isTldr
          title={TLDR.title}
          description={TLDR.description}
        />
      </div>
    </div>
  );
};

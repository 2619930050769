import { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import styles from './FeedPage.module.scss';

// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';
import { fetchPosts } from 'src/redux/slices/feedSlice';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import ScrollContainer from './VideoList/VideoList';

export const FeedPage: React.FC = () => {
  const { posts, currentPage, totalPages, isLoading } = useAppSelector(
    (state: any) => state.feed
  );
  const dispatch = useAppDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const touchStartY = useRef<number | null>(null);
  const touchEndY = useRef<number | null>(null);

  useEffect(() => {
    dispatch(fetchPosts({ page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    let hls: Hls | undefined;

    if (posts.length > 0 && videoRef.current) {
      const currentPost = posts[currentIndex];

      if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(currentPost.video_url);
        hls.attachMedia(videoRef.current);
      } else if (
        videoRef.current.canPlayType('application/vnd.apple.mpegurl')
      ) {
        videoRef.current.src = currentPost.video_url;
      }

      return () => {
        if (hls) {
          hls.destroy();
        }
      };
    }
  }, [currentIndex, posts]);

  const handleUpArrow = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleDownArrow = () => {
    if (currentIndex === posts.length - 1) {
      if (currentPage < totalPages) {
        dispatch(fetchPosts({ page: currentPage + 1 })).then(() => {
          // Automatically show the first post of the newly loaded page
          setCurrentIndex((prevIndex) => prevIndex + 1);
        });
      }
    } else {
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, posts.length - 1));
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartY.current = e.touches[0].clientY;
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    touchEndY.current = e.changedTouches[0].clientY;

    if (
      touchStartY.current !== null &&
      touchEndY.current !== null &&
      Math.abs(touchStartY.current - touchEndY.current) > 50
    ) {
      if (touchStartY.current > touchEndY.current) {
        handleDownArrow();
      } else {
        handleUpArrow();
      }
    }
    touchStartY.current = null;
    touchEndY.current = null;
  };

  if (isLoading && currentPage === 1) {
    return <MaterialCircularPogress />;
  }

  if (!posts.length) {
    return <div>No posts available</div>;
  }

  return (
    <div
      className={styles.feedPage}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <ScrollContainer
        posts={posts}
        loadMorePosts={(page) => dispatch(fetchPosts({ page }))}
        isLoading={isLoading}
        hasMorePosts={currentPage < totalPages}
        currentPage={currentPage}
      />
      {/* <div className={styles.videoContainer}>
        <div className={`${styles.videoWrapper}`}>
          <video
            onClick={handleVideoClick}
            ref={videoRef}
            controls={false}
            autoPlay
            loop
            className={styles.video}
            // preload="auto"
          />
          <div className={styles.dateOverlay}>{relativeTime}</div>

          <div className={styles.commentOverlay}>
            <ModeCommentIcon className={styles.commentIcon} />
            {currentPost.comments.length}
          </div>

          <div className={styles.challengeOverlay}>
            <div className={styles.tribeName}>{currentPost.tribe_name}</div>
            {displayedChallenges.map((challenge: any, index: any) => (
              <div className={styles.challengeItem} key={index}>
                <img
                  src={getCategoryImage(challenge.category)}
                  alt={challenge.category}
                  className={styles.challengeImage}
                />
                {truncateText(challenge.name, 40)}
              </div>
            ))}
            {moreChallengesCount > 0 && (
              <div className={styles.moreItem}>+{moreChallengesCount} more</div>
            )}
            <div
              className={styles.dunbarIconWrapper}
              onClick={() => setIsOpenModal(true)}
            >
              <img
                src={dunbarWhite}
                alt="Dunbar"
                className={styles.dunbarIcon}
              />
            </div>
          </div>
        </div>
        <div className={styles.navigationButtons}>
          <button onClick={handleUpArrow} className={styles.navButton}>
            <ArrowUpwardIcon />
          </button>
          <button onClick={handleDownArrow} className={styles.navButton}>
            <ArrowDownwardIcon />
          </button>
        </div>
      </div>

      <SelectedVideoModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        videoId={currentPost._id}
        videoUrl={currentPost.video_url}
      /> */}
    </div>
  );
};

import { FC } from 'react';
import styles from './SectionCompnent.module.scss';
import cn from 'classnames';

interface SectionComponentProps {
  title: string;
  description: (string | JSX.Element)[];
  isSmallTitle?: boolean;
  isTldr?: boolean;
  isAuction?: boolean;
}

export const SectionComponent: FC<SectionComponentProps> = ({
  title,
  description,
  isSmallTitle,
  isTldr,
  isAuction,
}) => {
  return (
    <div className={styles.container}>
      <div
        className={cn(styles.title, {
          [styles.isSmallTitle]: isSmallTitle,
          [styles.isTldr]: isTldr,
          [styles.isAuction]: isAuction,
        })}
      >
        {title}
      </div>
      <div className={styles.list}>
        {description.map((elem, index) => (
          <div key={index} className={styles.listItem}>
            {typeof elem === 'string' ? <div>{elem}</div> : elem}
          </div>
        ))}
      </div>
    </div>
  );
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postsApi } from 'src/api/postst';

interface Challenge {
  _id: string;
  name: string;
  category: string;
}

interface Post {
  _id: string;
  tribe_name: string;
  leader_name: string;
  video_id: string;
  video_url: string;
  challenges: Challenge[];
  comment_count: number;
  createdAt: string;
  comments: any;
}

interface FeedState {
  posts: Post[];
  currentPage: number;
  totalPages: number;
  isLoading: boolean;
  isFetchingMore: boolean;
  error: string | null;
}

const initialState: FeedState = {
  posts: [],
  currentPage: 1,
  totalPages: 1,
  isLoading: false,
  isFetchingMore: false,
  error: null,
};

// Fetch posts with pagination
export const fetchPosts = createAsyncThunk(
  'feed/fetchPosts',
  async ({ page }: { page: number }) => {
    const response = await postsApi.getAllPosts({
      page,
      pageSize: 10,
      videoRequired: true,
    });
    return response.data;
  }
);

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state, action) => {
        if (action.meta.arg.page === 1) {
          state.isLoading = true;
        } else {
          state.isFetchingMore = true;
        }
        state.error = null;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        const { result, totalPages } = action.payload;
        state.posts =
          action.meta.arg.page === 1 ? result : [...state.posts, ...result];
        state.totalPages = totalPages;
        state.isLoading = false;
        state.isFetchingMore = false;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch posts';
        state.isLoading = false;
        state.isFetchingMore = false;
      });
  },
});

export default feedSlice.reducer;

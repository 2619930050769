import api from './baseApi';

export const commentsApi = {
  postComment: (postId: string, data: any) => {
    return api.post(`posts/${postId}/comments`, data);
  },

  postReply: (postId: string, commentId: string, data: any) => {
    return api.post(`posts/${postId}/comments`, data);
  },

  likeComment: (postId: string, commentId: string) => {
    return api.put(`posts/${postId}/comments/${commentId}/like`);
  },

  changeComment: (postId: string, commentId: string, data: any) => {
    return api.patch(`posts/${postId}/comments/${commentId}`, {
      data,
    });
  },

  deleteComment: (postId: string, commentId: string) => {
    return api.delete(`posts/${postId}/comments/${commentId}`);
  },
};

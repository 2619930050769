import api from './baseApi';

export const adminPanelApi = {
  getCoupons: () => {
    return api.get(`/coupons/`);
  },

  getCouponsByCode: (code: string) => {
    return api.get(`/coupons?code=${code}`);
  },

  editCoupon: (id: number, data: any) => {
    return api.patch(`/coupons/${id}`, {
      coupon: data,
      // client,
      // max_users,
      // startDate,
      // endDate,
      // staus,
    });
  },

  createCoupon: (
    client: string,
    max_users: number,
    startDate: string,
    endDate: string
  ) => {
    return api.post(`/coupons/`, {
      client,
      max_users,
      startDate,
      endDate,
    });
  },
};

import { FC } from 'react';
import styles from './SubscriptionInfo.module.scss';
import { UserInfoProps } from '../const';

export const SubscriptionInfo: FC<UserInfoProps> = ({
  comment_count,
  like_count,
  result,
}) => {
  return (
    <div>
      <div className={styles.container}>
        {/* <div className={styles.info}>
          {comment_count} <span>Comments</span>
        </div> */}
        {/* <div className={styles.info}>
        50$<span>/month</span>
      </div> */}
        {/* <div className={styles.info}>
          {like_count} <span>Likes</span>
        </div> */}
      </div>
      <div className={styles.info}>
        <div className={styles.title}>Bio</div>
        <div className={styles.bio}>
          <span>{result.bio}</span>
        </div>
      </div>
    </div>
  );
};

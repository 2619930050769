import Avatar from '@mui/material/Avatar';
// import { Button } from '@mui/material';
import styles from './UserInfo.module.scss';
// import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
// import Popover from '@mui/material/Popover';
import { FC } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { PathRoutes } from 'src/const/Routes/PathRoutes';
// import { Link } from 'react-router-dom';
// import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { UserInfoProps } from 'src/pages/Home/const';
// import { SendMessageModal } from '../SendMessageModal/SendMessageModal';

export const UserInfo: FC<UserInfoProps> = ({ result }) => {
  // const navigate = useNavigate();
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [isOpenModal, setIsOpenModal] = useState(false);

  // const handleClick = (event: any) => {
  //   setAnchorEl(anchorEl ? null : event.currentTarget);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popper' : undefined;

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${result ? result.avatar_url : ''})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className={styles.avatar}>
        <Avatar
          src={result ? result.avatar_url : ''}
          sx={{ width: 85, height: 85 }}
        >
          {result.full_name.charAt(0)}
        </Avatar>
      </div>
      <div className={styles.info}>
        <div className={styles.data}>
          <div className={styles.title}>{result.full_name}</div>
          {/* <div className={styles.bio}>{result.bio}</div> */}
        </div>
        {/* <div className={styles.btns}>
          <Button
            type="submit"
            variant="contained"
            sx={{
              mb: 2,
              textTransform: 'none',
              fontSize: '14px',
            }}
            size="medium"
            onClick={() => setIsOpenModal(true)}
          >
            Send Message
          </Button>
        </div> */}
      </div>
      {/* <SendMessageModal
        defaultVideo=""
        onUpdate={() => console.log('1')}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      /> */}
      <div className={styles.iconsWrapper}>
        {/* <img src={arrow} alt="" className={styles.img} /> */}
        {/* <div onClick={handleClick}>
          <MoreVertRoundedIcon sx={{ color: 'black' }} />
        </div>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <div className={styles.popover}>
            <Link to={PathRoutes.CreateCircleRoute}> Create circle</Link>
          </div>
        </Popover> */}
      </div>
    </div>
  );
};

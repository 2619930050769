import { useState, FC } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { themeColors } from 'src/theme';
import ReactPlayer from 'react-player';
import { AuctionProps } from '../conts';
import styles from './Tabs.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const TabsAuction: FC<AuctionProps> = ({
  welcome_video_url,
  description,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const isWideScreen = useMediaQuery('(min-width:550px)');

  return (
    <Box sx={{ width: '100%', marginTop: '20px', paddingLeft: '0px' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{ sx: { background: themeColors.text.dark100 } }}
          sx={{
            '& button': { color: themeColors.text.dark70 },
            '& button.Mui-selected': { color: themeColors.text.dark100 },
          }}
        >
          <Tab label="Video" {...a11yProps(0)} />
          <Tab label="ABout" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className={styles.playerWrapper}>
          <ReactPlayer
            url={welcome_video_url}
            controls
            playing={false}
            width={isWideScreen ? 500 : 290}
            height={isWideScreen ? 500 : 522}
            className={styles.reactPlayer}
          />
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div className={styles.description}> {description}</div>
      </CustomTabPanel>
    </Box>
  );
};

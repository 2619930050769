import styles from './Accordion.module.scss';
import AccordionItem from './AccordionItem/AccordionItem';
import { FC, ReactNode } from 'react';
interface Item {
  question: string | JSX.Element;
  answer?: ReactNode;
}

interface AccordionProps {
  items: Item[];
  title: string;
}

const Accordion: FC<AccordionProps> = ({ items, title }) => {
  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.accordion}>
          {items.map((faq, index) => (
            <AccordionItem key={index} faq={faq} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Accordion;

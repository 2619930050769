import api from './baseApi';

interface UploadFileParams {
  contentType: string;
  name?: string;
  type?: string;
}

interface UploadFileResponse {
  uploadURL: string;
  Key: string;
}

export const usersApi = {
  userMe: () => {
    return api.get(`users/me`);
  },

  getUserById: (id: string) => {
    return api.get(`users/${id}`);
  },

  createProfile: (data: any, id: string) => {
    return api.patch(`users/${id}`, data);
  },

  editProfile: (data: any, id: string) => {
    return api.patch(`users/${id}`, data);
  },

  uploadFile: (
    params: UploadFileParams
  ): Promise<{ data: UploadFileResponse }> => {
    return api.post(`files/uploader`, params);
  },

  getUserCircle: (id: string) => {
    return api.get(`users/${id}`);
  },
};

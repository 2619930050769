import { BlockWithImg } from 'src/components/common/HowToPageComponents/BlockWIthImg/BlockWIthImg';
import screenOne from 'src/assets/images/howToPage/Picture31.png';
import screenTwo from 'src/assets/images/howToPage/Picture32.png';
import screenThree from 'src/assets/images/howToPage/Picture33.png';
import screenFour from 'src/assets/images/howToPage/Picture34.png';
import styles from './ForgotPassword.module.scss';
import {
  IconType,
  WarningBlock,
} from 'src/components/common/HowToPageComponents/WarningBlock/WarningBlock';

export const ForgotPssword = () => {
  return (
    <div>
      <div className={styles.blockTitle}>10- Forgot password</div>
      <div className={styles.container}>
        <BlockWithImg
          src={screenOne}
          title="10.1 Tap “Forgot password” to begin the recovery process."
        />
        <BlockWithImg
          src={screenTwo}
          title="10.2 Select your country code and enter your phone number."
        />
        <BlockWithImg
          src={screenThree}
          title="10.3 Enter a secure password and confirm it."
        />
      </div>
      <div className={styles.container}>
        <BlockWithImg
          src={screenFour}
          title={
            <>
              10.4 You will receive a code via <br />
              SMS. Enter it & tap Submit.
            </>
          }
        />
        <div className={styles.block}>
          <WarningBlock
            iconType={IconType.Positive}
            title={
              <>
                SMS messages may take several minutes to arrive. If an SMS does
                not arrive, you can tap <strong>Resend Code.</strong>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

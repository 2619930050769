import animals from 'src/assets/images/categories/animals_light.png';
import art from 'src/assets/images/categories/art_light.png';
import business from 'src/assets/images/categories/business_light.png';
import coaching from 'src/assets/images/categories/coaching_light.png';
import creativity from 'src/assets/images/categories/creativity_light.png';
import design from 'src/assets/images/categories/design_light.png';
import education from 'src/assets/images/categories/education_light.png';
import finance from 'src/assets/images/categories/finance_light.png';
import food from 'src/assets/images/categories/food_light.png';
import games from 'src/assets/images/categories/games_light.png';
import health from 'src/assets/images/categories/health_light.png';
import hobbies from 'src/assets/images/categories/hobbies_light.png';
import hr from 'src/assets/images/categories/hr_light.png';
import leadership from 'src/assets/images/categories/leadership_light.png';
import marketing from 'src/assets/images/categories/marketing_light.png';
import mindfulness from 'src/assets/images/categories/mindfulness_light.png';
import music from 'src/assets/images/categories/music_light.png';
import nature from 'src/assets/images/categories/nature_light.png';
import operations from 'src/assets/images/categories/operations_light.png';
import productivity from 'src/assets/images/categories/productivity_light.png';
import sales from 'src/assets/images/categories/sales_light.png';
import spirituality from 'src/assets/images/categories/spirituality_light.png';
import sports from 'src/assets/images/categories/sports_light.png';
import style from 'src/assets/images/categories/style_light.png';
import strategy from 'src/assets/images/categories/strategy_light.png';
import support from 'src/assets/images/categories/support_light.png';
import team from 'src/assets/images/categories/team_light.png';
import technology from 'src/assets/images/categories/technology_light.png';
import writing from 'src/assets/images/categories/writing_light.png';
import other from 'src/assets/images/categories/other_light.png';
import relationships from 'src/assets/images/categories/relationships_light.png';

export const CATEGORIES_LIGHT = [
  {
    name: 'animals',
    img: animals,
  },
  {
    name: 'art',
    img: art,
  },
  {
    name: 'business',
    img: business,
  },
  {
    name: 'coaching',
    img: coaching,
  },
  {
    name: 'creativity',
    img: creativity,
  },
  {
    name: 'design',
    img: design,
  },
  {
    name: 'education',
    img: education,
  },
  {
    name: 'finance',
    img: finance,
  },
  {
    name: 'food',
    img: food,
  },
  {
    name: 'games',
    img: games,
  },
  {
    name: 'health',
    img: health,
  },
  {
    name: 'hobbies',
    img: hobbies,
  },
  {
    name: 'hr',
    img: hr,
  },

  {
    name: 'leadership',
    img: leadership,
  },
  {
    name: 'marketing',
    img: marketing,
  },
  {
    name: 'mindfulness',
    img: mindfulness,
  },
  {
    name: 'music',
    img: music,
  },
  {
    name: 'nature',
    img: nature,
  },
  {
    name: 'operations',
    img: operations,
  },
  {
    name: 'music',
    img: music,
  },

  {
    name: 'productivity',
    img: productivity,
  },
  {
    name: 'relationships',
    img: relationships,
  },
  {
    name: 'sales',
    img: sales,
  },
  {
    name: 'spirituality',
    img: spirituality,
  },
  {
    name: 'sports',
    img: sports,
  },
  {
    name: 'spirituality',
    img: spirituality,
  },
  {
    name: 'style',
    img: style,
  },

  {
    name: 'strategy',
    img: strategy,
  },
  {
    name: 'support',
    img: support,
  },
  {
    name: 'team',
    img: team,
  },
  {
    name: 'technology',
    img: technology,
  },
  {
    name: 'writing',
    img: writing,
  },
  {
    name: 'other',
    img: other,
  },
];

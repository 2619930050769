import Avatar from '@mui/material/Avatar';
import { Button } from '@mui/material';
import styles from './UserInfo.module.scss';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { FC, useState } from 'react';
import { TribeDetail } from 'src/pages/Home/const';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import { AddMembersModal } from '../AddMembersModal/AddMembersModal';
import Popover from '@mui/material/Popover';
import { Link } from 'react-router-dom';
import { selectCurrentUser } from 'src/redux/slices/loggedUserSlice';
import { CATEGORIES } from 'src/const/Categories/Categories';
import { CategoryItem } from 'src/components/common/Category/CategoryItem/CategoryItem';
import CreateIcon from '@mui/icons-material/Create';
import useMediaQuery from '@mui/material/useMediaQuery';
import defaultUser from 'src/assets/images/defaults/default-user.webp';

export const UserInfo: FC<TribeDetail> = ({
  leader_name,
  leader_avatar_url,
  tribe_name,
  leader_id,
  _id,
  id,
  tribe_avatar_url,
  member_count_max = 0,
  members,
  habits = [],
  postLenght,
}) => {
  const [isOpenCreatePostModal, setIsOpenCreatePostModal] = useState(false);
  const user = useAppSelector(selectCurrentUser);

  const matches = useMediaQuery('(max-width:745px)');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const isLeader = user?._id === leader_id;

  const uniqueCategories = Array.from(
    new Set(habits.map((habit: any) => habit.category))
  );

  const getCategoryImage = (category: string) => {
    const categoryData = CATEGORIES.find((cat: any) => cat.name === category);
    return categoryData
      ? categoryData.img
      : CATEGORIES.find((cat: any) => cat.name === 'other')?.img;
  };

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: matches
          ? `url(${tribe_avatar_url || leader_avatar_url})`
          : defaultUser,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Avatar is hidden on mobile */}
      <div className={styles.avatar}>
        <Avatar
          src={tribe_avatar_url || leader_avatar_url}
          sx={{ width: 300, height: 300, borderRadius: '5px' }}
        />
      </div>
      <div className={styles.info}>
        <div className={styles.dataTop}>
          <div className={styles.title}>{tribe_name}</div>
          <div className={styles.bio}>{leader_name}</div>
        </div>
        <div className={styles.data}>
          <div className={styles.container_item}>
            <div className={styles.info}>
              {members.length} <span>Members</span>
            </div>
            <div className={styles.info}>
              {postLenght} <span>Posts</span>
            </div>
          </div>
          <div className={styles.categories}>
            <div className={styles.categoryList}>
              {uniqueCategories.map((category, index) => (
                <CategoryItem
                  key={index}
                  item={{ name: category, img: getCategoryImage(category) }}
                />
              ))}
            </div>
          </div>
        </div>
        <div>
          {isLeader && (
            <div>
              <Button
                onClick={() => setIsOpenCreatePostModal(true)}
                type="submit"
                variant="contained"
                sx={{
                  mb: 1,
                  textTransform: 'none',
                  fontSize: '14px',
                  width: '100%',
                  marginTop: matches ? '10px' : '30px',
                  marginLeft: matches ? '20px' : '0px',
                }}
                size="medium"
                fullWidth
              >
                Members
              </Button>
            </div>
          )}
          {/* {isLeader && (
            <div>
              <Button
                onClick={() => setIsOpenCreatePostModal(true)}
                type="submit"
                variant="contained"
                sx={{
                  // mt: 1,
                  mb: 1,
                  textTransform: 'none',
                  fontSize: '14px',
                  width: '100%',
                  marginTop: '30px',
                }}
                size="medium"
                fullWidth
              >
                Challenge +
              </Button>
            </div>
          )} */}
        </div>
        {/* <div>{<CreateIcon />}</div> */}
        <AddMembersModal
          isOpenModal={isOpenCreatePostModal}
          setIsOpenModal={setIsOpenCreatePostModal}
          circleId={_id}
          tribeName={tribe_name}
          isLeader={isLeader}
        />
      </div>
      <div className={styles.iconsWrapper}>
        {matches && isLeader ? (
          <Link to={`/edit-circle/${id}`}>
            {<CreateIcon sx={{ color: 'white' }} />}
          </Link>
        ) : (
          isLeader && (
            <>
              <div onClick={handleClick}>
                <MoreVertRoundedIcon sx={{ color: 'black' }} />
              </div>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              >
                <div className={styles.popover}>
                  <Link to={`/edit-circle/${id}`}>Edit circle</Link>
                </div>
              </Popover>
            </>
          )
        )}
      </div>
    </div>
  );
};

import { FC, ReactNode } from 'react';
import styles from './BlockWIthImg.module.scss';

interface BlockWithImgProps {
  title: string | ReactNode;
  bottomTitle?: string | ReactNode;
  src: string;
  width?: number;
  height?: number;
}

export const BlockWithImg: FC<BlockWithImgProps> = ({
  title,
  src,
  height,
  width,
  bottomTitle,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.img}>
        <img src={src} width={width} height={height} alt="" />
      </div>
      <div className={styles.title}>{bottomTitle}</div>
    </div>
  );
};

import { FC } from 'react';
import classNames from 'classnames';
import styles from './CategoryItem.module.scss';

interface CategoryItemProps {
  item: { name: string; img?: string };
  isSelected?: boolean;
  onClick?: () => void;
}

export const CategoryItem: FC<CategoryItemProps> = ({
  item,
  isSelected,
  onClick,
}) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div
        className={classNames(styles.block, { [styles.selected]: isSelected })}
      >
        <img src={item.img} width={17} height={17} alt="" />
        {item.name}
      </div>
    </div>
  );
};

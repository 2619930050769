import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { PathRoutes } from 'src/const/Routes/PathRoutes';

export const ThirdStep = () => {
  return (
    <Box sx={{ textAlign: 'center', mt: 5 }}>
      <Typography
        variant="h4"
        align="center"
        sx={{ mt: 2, mb: 2, color: '#191920', fontWeight: 'bold' }}
      >
        Password Reset Successfully
      </Typography>
      <Typography
        variant="body1"
        align="center"
        sx={{ mt: 2, mb: 4, color: '#191920' }}
      >
        You can now log in with your new password.
      </Typography>
      <Link to={PathRoutes.LoginRoute} style={{ textDecoration: 'none' }}>
        <Button variant="contained" fullWidth size="large">
          Go to Login
        </Button>
      </Link>
    </Box>
  );
};

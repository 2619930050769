import { useState } from 'react';
import styles from './AdminPage.module.scss';
import CouponsTable from './CouponsTable/CouponsTable';
import { CreateCouponsModal } from './CreateCouponsModal/CreateCouponsModal';
import { Button } from '@mui/material';

export const AdminPage = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <div className={styles.container}>
      <div>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            textTransform: 'none',
            fontSize: '18px',
            width: '232px',
          }}
          size="large"
          onClick={() => setIsOpenModal(true)}
        >
          Generate coupon
        </Button>
      </div>
      <CreateCouponsModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      />
      <div className={styles.scrollableContent}>
        <CouponsTable />
      </div>
    </div>
  );
};

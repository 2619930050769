import styles from './RegisterModal.module.scss';
import { LoginType } from './LoginType/LoginType';
import { useState } from 'react';
import { SignUpType } from './SignUpType/SignUpType';
import { ForgetPasswordType } from './ForgotPasswordType/ForgetPasswordType';

export const RegisterModal = () => {
  const [step, setStep] = useState(1);

  const stepMapper: { [key: number]: JSX.Element } = {
    1: <LoginType setStep={setStep} />,
    2: <SignUpType setType={setStep} />,
    3: <ForgetPasswordType setType={setStep} />,
  };

  return <div className={styles.container}>{stepMapper[step]}</div>;
};

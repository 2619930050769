import screenSix from 'src/assets/images/howToPage/Picture40.jpg';
import styles from './CommonIssue.module.scss';

export const CommonIssue = () => {
  return (
    <div>
      <div className={styles.blockTitle}>11- Common issues</div>
      <div>
        <div className={styles.top}>
          <img src={screenSix} alt="" />
          <div>
            <div>
              <div className={styles.title}>
                <strong>Issue:</strong> I cannot upload images and/or videos
                from my gallery.
              </div>
              <div className={styles.title}>
                <strong>Solution:</strong> check in your app permissions in your
                phone’s native settings that the Dunbar app has access to your
                photo gallery.{' '}
              </div>
            </div>
            <div className={styles.wrapper}>
              <div className={styles.title}>
                <strong>Issue:</strong> I cannot take photos or record videos.
              </div>
              <div className={styles.title}>
                <strong>Solution:</strong> check in your app permissions in your
                phone’s native settings that the Dunbar app has access to use
                the camera and/or access the microphone.
              </div>
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <strong>Issue:</strong> I don’t see any Challenges on the main
            screen.
          </div>
          <div className={styles.title}>
            <strong>Solution:</strong> contact your Circle Leader and/or
            learning team to add you to their Circle(s).
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <strong>Issue:</strong> When I try to create my account, I am not
            receiving a verification message.
          </div>
          <div className={styles.title}>
            <strong>Solution:</strong>
            <ol>
              <li>
                First check that you are able to send and receive text messages.
                This may be a network or connectivity issue.{' '}
              </li>
              <li>
                Next, if you are receiving messages, press the resend code
                button that appears on the verification screen after a short
                delay. This message may take several minutes to arrive.{' '}
              </li>
              <li>
                Next, if you still have not received the message, confirm that
                you have entered the correct phone number.{' '}
              </li>
              <li>
                Restart the app, and follow the Join Dunbar instructions again.{' '}
              </li>
              <li>
                If a message still has not arrived and all the previous steps
                have been completed, <br />
                please email the Dunbar support team for assistance and further
                instructions.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { themeColors } from 'src/theme';
import MaterialTextField from 'src/components/material/textField';
import WestIcon from '@mui/icons-material/West';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import styles from './FirstStep.module.scss';

export interface FirstStepProps {
  error: string;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  handleSendCode: () => void;
}

export const FirstStep: React.FC<FirstStepProps> = ({
  error,
  phoneNumber,
  setPhoneNumber,
  handleSendCode,
}) => {
  return (
    <Box sx={{ width: '92%' }}>
      {error && (
        <Typography variant="body2" color="error" align="center">
          {error}
        </Typography>
      )}
      <MaterialTextField
        isPhoneNumber
        label="Phone Number"
        name="phoneNumber"
        id="phone-number"
        variant="outlined"
        error={!!error}
        onChange={(value: any) => setPhoneNumber(value)}
        value={phoneNumber}
        color="primary"
      />
      <Button
        type="submit"
        fullWidth
        onClick={handleSendCode}
        variant="contained"
        sx={{ mt: 3, mb: 2, textTransform: 'none', fontSize: '18px' }}
        size="large"
      >
        Send Code
      </Button>
      <Link to={PathRoutes.LoginRoute} className={styles.link}>
        <Button
          className={styles['btn']}
          sx={{
            background: themeColors.secondary.main,
            color: themeColors.secondary.text,
          }}
          fullWidth
          variant="text"
          size="large"
        >
          <WestIcon /> Back
        </Button>
      </Link>
    </Box>
  );
};

import screenOne from 'src/assets/images/howToPage/Picture28.png';
import screenTwo from 'src/assets/images/howToPage/Picture29.png';
import { BlockWithImg } from 'src/components/common/HowToPageComponents/BlockWIthImg/BlockWIthImg';
import styles from './SignOut.module.scss';

export const SignOut = () => {
  return (
    <div>
      <div className={styles.blockTitle}>8- Sign out</div>
      <div className={styles.container}>
        <BlockWithImg
          src={screenOne}
          title={
            <>
              8.1 From your Feed, tap on your
              <br /> avatar to open the user menu.
            </>
          }
        />
        <BlockWithImg
          src={screenTwo}
          title={
            <>
              8.2 In the user menu, tap the
              <br /> Log out button.
            </>
          }
        />
      </div>
    </div>
  );
};

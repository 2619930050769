import { BlockWithImg } from 'src/components/common/HowToPageComponents/BlockWIthImg/BlockWIthImg';
import screenOne from 'src/assets/images/howToPage/Picture18.png';
import screenTwo from 'src/assets/images/howToPage/Picture19.png';
import screenThree from 'src/assets/images/howToPage/Picture20.png';
import screenFour from 'src/assets/images/howToPage/Picture21.png';
import screenFive from 'src/assets/images/howToPage/Picture22.png';
import screenSix from 'src/assets/images/howToPage/Picture23.png';
import styles from './CreateCircle.module.scss';
import {
  IconType,
  WarningBlock,
} from 'src/components/common/HowToPageComponents/WarningBlock/WarningBlock';

export const CreateCircle = () => {
  return (
    <div>
      <div className={styles.blockTitle}>6- Create a Circle</div>
      <div className={styles.container}>
        <BlockWithImg
          src={screenOne}
          title="6.1 From your Feed, tap on your avatar to open the user menu."
        />
        <BlockWithImg
          src={screenTwo}
          title="6.2 In the user menu, tap the Add Circle button."
        />
        <BlockWithImg
          src={screenThree}
          title={
            <>
              6.3 To create a Circle, you must include a welcome video. This
              video will be used later as a “welcome” experience for new
              Members. <br />
              <br />
              You may <strong>record</strong> your video live or pre-record and
              <strong>upload</strong> it from your photo gallery.
            </>
          }
          bottomTitle={
            <>
              We recommend recording live to provide your Members with a more
              genuine, authentic experience and set the tone that their
              contributions should follow suit and not be overly polished.
            </>
          }
        />
      </div>
      <div className={styles.container}>
        <BlockWithImg
          src={screenFour}
          title="6.4 This next screen shows a preview of your Circle. "
        />
        <div className={styles.description}>
          6.4 continued…
          <br />
          <br />
          Buttons outlined in red indicate required fields that have not yet
          been updated.
          <br />
          <br />
          Tapping a button will open the editor for that specific information.
          Each of these editors contains its own easy-to-follow instructions,
          which will not be repeated here
        </div>
        <BlockWithImg
          src={screenFive}
          title="6.5 An example of a completed Circle, ready to submit."
        />
      </div>
      <div className={styles.content}>
        <BlockWithImg src={screenSix} title="6.6 Success!" />
        <div className={styles.info}>
          <WarningBlock
            iconType={IconType.Positive}
            title={
              <>
                Congratulations! You have successfully created your{' '}
                <strong>Circle!</strong>{' '}
              </>
            }
          />
          <WarningBlock
            iconType={IconType.Warning}
            title="Note: All new Circles are reviewed by Dunbar staff.*"
          />
          <div className={styles.text}>
            *Dunbar reserves the right to reject any new Circle or close any
            existing Circle without prior warning and without providing a
            reason.
          </div>
        </div>
      </div>
    </div>
  );
};

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { usersApi } from 'src/api/user';

// Define interfaces for the user and state
interface Device {
  device_token: string;
  manufacturer_id: string;
  arn: string;
  _id: string;
}

interface User {
  _id: string;
  phone_number: string;
  last_login_date: string | null;
  blocked_users: string[];
  deleted: boolean;
  sign_up_date: string;
  tribes: string[];
  devices: Device[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  avatar_url: string;
  bio: string;
  birthday: string;
  email: string;
  full_name: string;
  stripe_account_status: string;
  role: string;
}

interface TribeDetail {
  _id: string;
  leader_name?: string;
  leader_id: string;
  leader_avatar_url?: string;
  tribe_name?: string;
  tribe_avatar_url?: string;
  status: string;
  role: string;
  join_status: string;
  account_status: string;
}

interface ApiResponse {
  result: User;
  tribe_details: TribeDetail[];
  comment_count: number;
  like_count: number;
}

interface LoggedUserState {
  user: User | null;
  tribeDetails: TribeDetail[];
  commentCount: number;
  likeCount: number;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

// Initial state
const initialState: LoggedUserState = {
  user: null,
  tribeDetails: [],
  commentCount: 0,
  likeCount: 0,
  status: 'idle',
  error: null,
};

// Async thunk to fetch user data
export const fetchLoggedUser = createAsyncThunk<ApiResponse>(
  'user/fetchLoggedUser',
  async () => {
    const response = await usersApi.userMe();
    return response.data;
  }
);

// Slice for the logged-in user
const loggedUserSlice = createSlice({
  name: 'loggedUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoggedUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchLoggedUser.fulfilled,
        (state, action: PayloadAction<ApiResponse>) => {
          state.status = 'succeeded';
          state.user = action.payload.result;
          state.tribeDetails = action.payload.tribe_details;
          state.commentCount = action.payload.comment_count;
          state.likeCount = action.payload.like_count;
        }
      )
      .addCase(fetchLoggedUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to load user data';
      });
  },
});

// Selector for the current logged-in user
export const selectCurrentUser = (state: { loggedUser: LoggedUserState }) =>
  state.loggedUser.user;
export const selectTribeDetails = (state: { loggedUser: LoggedUserState }) =>
  state.loggedUser.tribeDetails;
export const selectCommentCount = (state: { loggedUser: LoggedUserState }) =>
  state.loggedUser.commentCount;
export const selectLikeCount = (state: { loggedUser: LoggedUserState }) =>
  state.loggedUser.likeCount;

// Export the reducer
export default loggedUserSlice.reducer;

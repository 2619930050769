import { z } from 'zod';

export interface IFormInput {
  phoneNumber: string;
  password: string;
  confirmationCode?: string;
}
export interface FirstStepInput {
  phoneNumber: string;
  password: string;
}

export interface SecondStepInput {
  confirmationCode: string;
}

export const schema = z.object({
  phoneNumber: z.string().min(10, 'Phone number must be at least 10 digits'),
  password: z
    .string()
    .min(8, 'Password must be at least 8 characters')
    .regex(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .regex(/[0-9]/, 'Password must contain at least one number'),
  confirmationCode: z.string().optional(),
});

export const secondStepSchema = z.object({
  confirmationCode: z
    .string()
    .min(6, 'Confirmation code must be at least 6 digits'),
});

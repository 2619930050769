import logo from '../../../assets/images/logos/logo-with-text_dark.png';
import { Box } from '@mui/material';
import styles from './Logo.module.scss';

export const Logo = () => {
  return (
    <Box sx={{ mt: 1, mb: 0 }}>
      <img className={styles.logo} src={logo} alt="logo" />
    </Box>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import useMediaQuery from '@mui/material/useMediaQuery';

const List = styled.div`
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  display: flex;
  flex-direction: column;

  /* Hide scrollbar */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
`;

const Item = styled.div<{ isWideScreen: boolean }>`
  scroll-snap-align: start;
  flex-shrink: 0;
  ${(props) =>
    props.isWideScreen
      ? css`
          height: 100vh;
        `
      : css`
          height: 93vh;
        `}
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Loader = styled.div`
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

interface ScrollContainerProps {
  posts: Array<any>;
  loadMorePosts: (page: number) => void; // Pass page number to loadMorePosts
  isLoading: boolean;
  hasMorePosts: boolean;
  currentPage: number;
}

const ScrollContainer: React.FC<ScrollContainerProps> = ({
  posts,
  loadMorePosts,
  isLoading,
  hasMorePosts,
  currentPage,
}) => {
  const videoObserver = useRef<IntersectionObserver | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0); // Track currently visible post index
  const isWideScreen = useMediaQuery('(min-width:550px)');
  const requestedPages = useRef<Set<number>>(new Set()); // Track requested pages

  // Play/pause videos based on visibility
  useEffect(() => {
    const videoElements = document.querySelectorAll<HTMLVideoElement>('video');

    const handleVideoIntersect = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        const video = entry.target as HTMLVideoElement;
        const index = Number(video.dataset.index);

        if (!isNaN(index) && entry.isIntersecting) {
          video.play().catch(() => {
            // Handle play promise rejection
          });
          setCurrentIndex(index); // Update current index
        } else {
          video.pause();
        }
      });
    };

    videoObserver.current = new IntersectionObserver(handleVideoIntersect, {
      threshold: 0.75,
    });

    videoElements.forEach((video) => videoObserver.current?.observe(video));

    return () => {
      videoElements.forEach((video) => videoObserver.current?.unobserve(video));
      videoObserver.current?.disconnect();
    };
  }, [posts]);

  // Fetch more posts when reaching the last post
  useEffect(() => {
    if (
      currentIndex === posts.length - 2 && // If last post is visible
      hasMorePosts && // If more posts are available
      !isLoading && // If not already loading
      !requestedPages.current.has(currentPage + 1) // If the next page hasn't been requested yet
    ) {
      loadMorePosts(currentPage + 1);
      requestedPages.current.add(currentPage + 1); // Mark the next page as requested
    }
  }, [
    currentIndex,
    posts.length,
    loadMorePosts,
    hasMorePosts,
    isLoading,
    currentPage,
  ]);

  return (
    <List>
      {posts.map((post, index) => (
        <Item key={post._id || index} isWideScreen={isWideScreen}>
          <VideoPlayer
            post={post}
            videoUrl={post.video_url}
            index={index} // Pass index as a prop
          />
        </Item>
      ))}
      {isLoading && <Loader>Loading...</Loader>}
    </List>
  );
};

export default ScrollContainer;

export const NAME_FIELD = {
  title: 'Name',
  description:
    'Dunbar Circle are small, closed, and foster genuine interactions, so most people use their real names.',
  placeholder: 'Your first and last name',
  charactersLength: 100,
};

export const EMAIL_FIELD = {
  title: 'Email',
  description:
    'Adding your email address helps keep your account secure and will give you another option to retest your password',
  placeholder: 'Your email address',
  charactersLength: 254,
};

export const BIO_FIELD = {
  title: 'Bio',
  description:
    'At Dunbar, we value genuine connections over polished facades. To enrich your experience, we encourage you to  be open and generous in your profile. Embrace vulnerability, celebrate your uniqueness, and let your story shine',
  placeholder: 'About you',
  charactersLength: 1500,
};

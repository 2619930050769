import React, { FC } from 'react';
import styles from '../CommentItem.module.scss';
import { Comment } from '../../../const';

interface CommentContentProps {
  comment: Comment;
  isEditing: boolean;
  editedText: string;
  setEditedText: React.Dispatch<React.SetStateAction<string>>;
  videoRef: React.RefObject<HTMLVideoElement>;
  handleVideoClick: () => void;
  handleSaveEdit: () => void;
}

export const CommentContent: FC<CommentContentProps> = ({
  comment,
  isEditing,
  editedText,
  setEditedText,
  videoRef,
  handleVideoClick,
  handleSaveEdit,
}) => (
  <div className={styles.commentContent}>
    {isEditing ? (
      <div className={styles.inputWrapper}>
        <input
          type="text"
          className={styles.commentInput}
          value={editedText}
          onChange={(e) => setEditedText(e.target.value)}
          placeholder="Edit your comment"
        />
        <div className={styles.postButton} onClick={handleSaveEdit}>
          Save
        </div>
      </div>
    ) : (
      <>
        {comment.media_type === 'image' ? (
          <img
            src={comment.media_url || ''}
            alt="Comment media"
            className={styles.commentImage}
          />
        ) : comment.media_type === 'video' ? (
          <video
            ref={videoRef}
            controls
            onClick={handleVideoClick}
            className={styles.commentVideo}
          >
            Your browser does not support the video tag.
          </video>
        ) : (
          <p>{comment.text}</p>
        )}
      </>
    )}
  </div>
);

import { BlockWithImg } from 'src/components/common/HowToPageComponents/BlockWIthImg/BlockWIthImg';
import styles from './CreateProfile.module.scss';
import screenOne from 'src/assets/images/howToPage/Picture7.png';
import screenTwo from 'src/assets/images/howToPage/Picture8.png';
import screenThree from 'src/assets/images/howToPage/Picture9.png';
import screenFour from 'src/assets/images/howToPage/Picture10.png';
import screenFive from 'src/assets/images/howToPage/Picture11.png';
import screenSix from 'src/assets/images/howToPage/Picture12.png';
import {
  IconType,
  WarningBlock,
} from 'src/components/common/HowToPageComponents/WarningBlock/WarningBlock';

export const CreateProfile = () => {
  return (
    <div>
      <div className={styles.blockTitle}>3- Create profile</div>
      <div className={styles.content}>
        <BlockWithImg
          title={
            <>
              3.1 Enter your full name as you prefer to be called. Do not use
              nicknames or “screen names”.{' '}
            </>
          }
          src={screenOne}
        />
        <BlockWithImg
          title={
            <>
              3.2 Take a photo of yourself for your profile photo.
              <br />
              <br />
            </>
          }
          src={screenTwo}
        />
        <BlockWithImg
          title="3.3 Create a bio about yourself. This can range from personal interests to professional goals. "
          src={screenThree}
        />
      </div>
      <div className={styles.content}>
        <BlockWithImg
          title="3.4 Add your birthday. Birth year is private information."
          src={screenFour}
        />
        <BlockWithImg
          title="3.5 Enter your email address. This is private information."
          src={screenFive}
        />
        <BlockWithImg
          title={
            <>
              3.6 Review, then save to finish.
              <br />
              <br />
            </>
          }
          src={screenSix}
        />
      </div>
      <WarningBlock
        iconType={IconType.Positive}
        title={
          <>
            Congratulations! You have successfully created your{' '}
            <strong>profile!</strong>{' '}
          </>
        }
      />
    </div>
  );
};

import React from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Popover from '@mui/material/Popover';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import styles from '../CommentItem.module.scss';
import { Comment } from '../../../const';

interface CommentHeaderProps {
  comment: Comment;
  userId: string | null;
  onClickMore: (event: React.MouseEvent<HTMLElement>) => void;
  anchorEl: HTMLElement | null;
  onClosePopover: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export const CommentHeader: React.FC<CommentHeaderProps> = ({
  comment,
  userId,
  onClickMore,
  anchorEl,
  onClosePopover,
  onEdit,
  onDelete,
}) => {
  const handleEdit = () => {
    onClosePopover();
    onEdit();
  };

  const handleDelete = () => {
    onClosePopover();
    onDelete();
  };

  return (
    <div className={styles.commentHeader}>
      <div className={styles.commentAuthor}>
        <img
          src={comment.author.avatar_url}
          alt={comment.author.name}
          className={styles.avatar}
        />
        <div className={styles.authorName}>{comment.author.name}</div>
      </div>
      {userId === comment.author.user_id && (
        <div onClick={onClickMore}>
          <MoreHorizIcon sx={{ color: 'black', cursor: 'pointer' }} />
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClosePopover}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <div className={styles.popover}>
              <div className={styles.popoverEdit} onClick={handleEdit}>
                Edit
                <EditOutlinedIcon sx={{ fontSize: 19 }} />
              </div>
              <div className={styles.popoverDelete} onClick={handleDelete}>
                Delete
                <DeleteOutlineOutlinedIcon
                  sx={{ fontSize: 19, color: 'red' }}
                />
              </div>
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
};

import { useForm, Controller } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import { useUserLoginLogout } from 'src/hooks/UserLoginLogout/UserLoginLogout';
import { Link, useNavigate } from 'react-router-dom';
import phoneScreen from '../../assets/images/screen/phone-screen.jpeg';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import MaterialTextField from 'src/components/material/textField';
import { fetchUser } from 'src/redux/slices/userSlice';
import { Copyright } from 'src/components/material/Copyright';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import styles from './Login.module.scss';
import { Logo } from 'src/components/common/Logo/Logo';

const schema = z.object({
  phoneNumber: z.string().min(10, 'Phone number must be at least 10 digits'),
  password: z
    .string()
    .min(8, 'Password must be at least 8 characters')
    .regex(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .regex(/[0-9]/, 'Password must contain at least one number'),
});

interface IFormInput {
  phoneNumber: string;
  password: string;
}

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { login, error } = useUserLoginLogout();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data: any) => {
    const result = await login({
      username: data.phoneNumber,
      password: data.password,
    });
    if (result?.isSignedIn) {
      dispatch(fetchUser());
      navigate(PathRoutes.HomeRoute);
    } else {
      reset({
        phoneNumber: data.phoneNumber,
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <Box
          sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo />
          <Box
            sx={{
              mt: 0,
              mb: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#191920',
              fontSize: '24px',
              fontWeight: '600',
            }}
          >
            Login
          </Box>
          <Box
            sx={{
              mt: 1,
              mb: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#191920',
              gap: '4px',
              fontWeight: '500',
            }}
          >
            New to Dunbar ?{' '}
            <Link to={PathRoutes.SignUpRoute} className={styles.link}>
              Create account for free
            </Link>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <MaterialTextField
                  isPhoneNumber
                  label="Phone Number"
                  variant="outlined"
                  error={!!errors.phoneNumber}
                  helperText={
                    errors.phoneNumber ? errors.phoneNumber.message : ''
                  }
                  {...field}
                  color="primary"
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <MaterialTextField
                  label="Password"
                  variant="outlined"
                  isPassword
                  error={!!errors.password}
                  helperText={errors.password ? errors.password.message : error}
                  {...field}
                  color="success"
                />
              )}
            />
            <Box
              sx={{
                mt: 0,
                mb: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '100%',
                color: '#191920',
                fontSize: '12px',
              }}
            >
              <Link to={PathRoutes.ForgetPasswordRoute} className={styles.link}>
                Forgot your password?
              </Link>
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, textTransform: 'none', fontSize: '18px' }}
              size="large"
            >
              Continue
            </Button>
          </form>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4, color: '#191920' }} />
      </div>
      <div className={styles.rightSide}>
        <img
          src={phoneScreen}
          className={styles.screenLogo}
          alt="phone screen"
        />
      </div>
    </div>
  );
};

import Avatar from '@mui/material/Avatar';
import { Button } from '@mui/material';
import styles from './UserInfo.module.scss';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import Popover from '@mui/material/Popover';
import { FC, useState } from 'react';
import { UserInfoProps } from '../const';
import { useNavigate } from 'react-router-dom';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import { Link } from 'react-router-dom';
// import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
// import { AddMembersModal } from '../../UserCircle/AddMembersModal/AddMembersModal';
import useMediaQuery from '@mui/material/useMediaQuery';
// import CreateIcon from '@mui/icons-material/Create';

export const UserInfo: FC<UserInfoProps> = ({
  result,
  comment_count,
  like_count,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [isOpenCreatePostModal, setIsOpenCreatePostModal] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const matches = useMediaQuery('(max-width:745px)');

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popper' : undefined;

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: matches ? `url(${result.avatar_url || ''})` : '',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className={styles.avatar}>
        <Avatar
          src={result ? result.avatar_url : ''}
          sx={{ width: 300, height: 300, borderRadius: '5px' }}
        >
          {result.full_name.charAt(0)}
        </Avatar>
      </div>
      <div className={styles.info}>
        <div className={styles.data}>
          <div className={styles.title}>{result.full_name}</div>
          {/* <div className={styles.bio}>{result.bio}</div> */}
        </div>

        <div>
          <div className={styles.containerMiddle}>
            <div className={styles.infoMiddle}>
              {comment_count} <span>Comments</span>
            </div>
            {/* <div className={styles.info}>
        50$<span>/month</span>
      </div> */}
            <div className={styles.infoMiddle}>
              {like_count} <span>Likes</span>
            </div>
          </div>
          {/* <div className={styles.infoMiddle}>
            <div className={styles.titleMiddle}>Bio</div>
            <div>
              <span>{result.bio}</span>
            </div>
          </div> */}
        </div>
        <div className={styles.btns}>
          <Button
            type="submit"
            variant="contained"
            sx={{
              mb: 2,
              textTransform: 'none',
              fontSize: '14px',
            }}
            size="medium"
            className={matches ? '' : styles.btn}
            onClick={() => navigate(PathRoutes.EditProfileRoute)}
          >
            Edit Profile
          </Button>
          {matches && (
            <Button
              type="submit"
              variant="contained"
              sx={{
                mb: 2,
                textTransform: 'none',
                fontSize: '14px',
              }}
              size="medium"
              // className={styles.btn}
              onClick={() => navigate(PathRoutes.CreateCircleRoute)}
            >
              Create Circle
            </Button>
          )}
        </div>
      </div>
      {!matches && (
        <div className={styles.iconsWrapper}>
          {/* <img src={arrow} alt="" className={styles.img} /> */}
          <div onClick={handleClick}>
            <MoreVertRoundedIcon sx={{ color: 'black' }} />
          </div>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <div className={styles.popover}>
              <Link to={PathRoutes.CreateCircleRoute}> Create circle</Link>
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
};

import { Typography, TypographyProps } from '@mui/material';

export const Copyright = (props: TypographyProps) => {
  return (
    <Typography variant="body2" color="text.primary" align="center" {...props}>
      {'Copyright © '}
      <a
        href="https://dunbar-app.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Dunbar
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

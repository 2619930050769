import Avatar from '@mui/material/Avatar';
import { Button } from '@mui/material';
import styles from './UserInfo.module.scss';
// import arrow from 'src/assets/images/arrow/arrow-icon.png';
import { FC, useState } from 'react';
import { JoinModal } from '../JoinModal/JoinModal';
import { AuctionProps } from '../conts';

export const UserInfo: FC<AuctionProps> = ({
  leader_name,
  tribe_name,
  tribe_avatar_url,
  price,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <Avatar src={tribe_avatar_url} sx={{ width: 85, height: 85 }}>
          N
        </Avatar>
      </div>
      <div className={styles.info}>
        <div className={styles.data}>
          <div className={styles.title}>{tribe_name}</div>
          <div className={styles.name}>{leader_name}</div>
        </div>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 1, mb: 2, textTransform: 'none', fontSize: '14px' }}
          size="medium"
          fullWidth
          onClick={() => setIsOpenModal(true)}
        >
          Join Circle
        </Button>
        <JoinModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          leader_name={leader_name}
          tribe_name={tribe_name}
          tribe_avatar_url={tribe_avatar_url}
          price={price}
        />
      </div>
      <div className={styles.iconsWrapper}>
        {/* <img src={arrow} alt="" className={styles.img} /> */}
        {/* <MoreVertRoundedIcon sx={{ color: 'black' }} /> */}
      </div>
    </div>
  );
};

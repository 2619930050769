import { FC, useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import { VideoItemProps } from 'src/pages/UserCircle/conts';
import styles from './VideoItem.module.scss';
import { SelectedVideoModal } from '../SelectedVideoModal/SelectedVideoModal';
import { CATEGORIES_LIGHT } from 'src/const/Categories/CategoresLight';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import dunbarWhite from 'src/assets/images/dunbar/dunbar-white.png';

export const VideoItem: FC<VideoItemProps> = ({ item }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    let hls: Hls | undefined;

    if (Hls.isSupported()) {
      hls = new Hls();
      hls.loadSource(item.video_url);
      hls.attachMedia(videoRef.current!);
    } else if (videoRef.current?.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = item.video_url;
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [item.video_url]);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const getCategoryImage = (category: string) => {
    const categoryData = CATEGORIES_LIGHT.find(
      (cat: any) => cat.name === category
    );
    return categoryData
      ? categoryData.img
      : CATEGORIES_LIGHT.find((cat: any) => cat.name === 'other')?.img;
  };

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const calculateRelativeTime = (date: string) => {
    const now = new Date();
    const createdAt = new Date(date);
    const diffInSeconds = Math.floor(
      (now.getTime() - createdAt.getTime()) / 1000
    );

    const intervals = [
      { label: 'year', seconds: 31536000 },
      { label: 'month', seconds: 2592000 },
      { label: 'week', seconds: 604800 },
      { label: 'day', seconds: 86400 },
      { label: 'hour', seconds: 3600 },
      { label: 'minute', seconds: 60 },
      { label: 'second', seconds: 1 },
    ];

    for (const interval of intervals) {
      const count = Math.floor(diffInSeconds / interval.seconds);
      if (count >= 1) {
        return count === 1
          ? `1 ${interval.label} ago`
          : `${count} ${interval.label}s ago`;
      }
    }
    return 'just now';
  };

  const displayedChallenges = item.challenges.slice(0, 2);
  const moreChallengesCount =
    item.challenges.length - displayedChallenges.length;

  const relativeTime = calculateRelativeTime(item.createdAt);

  return (
    <div className={styles.videoContainer}>
      <video
        ref={videoRef}
        onClick={handleVideoClick}
        className={styles.video}
        playsInline
      >
        Your browser does not support the video tag.
      </video>

      <div className={styles.dateOverlay}>{relativeTime}</div>

      <div className={styles.commentOverlay}>
        <ModeCommentIcon className={styles.commentIcon} />
        {item.comments.length}
      </div>

      <div className={styles.challengeOverlay}>
        {displayedChallenges.map((challenge, index) => (
          <div className={styles.challengeItem} key={index}>
            <img
              src={getCategoryImage(challenge.category)}
              alt={challenge.category}
              className={styles.challengeImage}
            />
            {truncateText(challenge.name, 20)}
          </div>
        ))}
        {moreChallengesCount > 0 && (
          <div className={styles.moreItem}>+{moreChallengesCount} more</div>
        )}
        <div
          className={styles.dunbarIconWrapper}
          onClick={() => setIsOpenModal(true)}
        >
          <img src={dunbarWhite} alt="Dunbar" className={styles.dunbarIcon} />
        </div>
      </div>

      <SelectedVideoModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        videoId={item._id}
        videoUrl={item.video_url}
      />
    </div>
  );
};

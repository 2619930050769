import { SectionComponent } from '../SectionCompnent/SectionCompnent';
import { CHALLENGE, CIRCLES, LEADERS, MEMBERS } from '../const';
import styles from './Terminolagy.module.scss';

export const Terminolagy = () => {
  return (
    <div className={styles.container}>
      <div id="terminology-circle">
        <SectionComponent
          isSmallTitle
          title={CIRCLES.title}
          description={CIRCLES.description}
        />
      </div>
      <div id="terminology-leaders">
        <SectionComponent
          isSmallTitle
          title={LEADERS.title}
          description={LEADERS.description}
        />
      </div>
      <div id="terminology-members">
        <SectionComponent
          isSmallTitle
          title={MEMBERS.title}
          description={MEMBERS.description}
        />
      </div>
      <div id="terminology-challenges">
        <SectionComponent
          isSmallTitle
          title={CHALLENGE.title}
          description={CHALLENGE.description}
        />
      </div>
    </div>
  );
};

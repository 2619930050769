import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { circleApi } from 'src/api/circles';
import { Member } from 'src/pages/UserCircle/conts';
import { RootState } from 'src/redux/store/store';

interface MemberState {
  members: Member[];
  loading: boolean;
  error: string | null;
}

const initialState: MemberState = {
  members: [],
  loading: false,
  error: null,
};

// Thunk to fetch members of a circle
export const getMembersOfCircle = createAsyncThunk(
  'members/getMembersOfCircle',
  async (circleId: string, { rejectWithValue }) => {
    try {
      const response = await circleApi.getMembersOfCircle(circleId);
      return response.data.result;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunk to invite a member
export const inviteMember = createAsyncThunk(
  'members/inviteMember',
  async (
    {
      tribe_id,
      role,
      phone_number,
    }: { tribe_id: string; role: string; phone_number: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await circleApi.inviteMember(
        tribe_id,
        role,
        phone_number
      );
      return response.data.result;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunk to remove a member
export const removeMember = createAsyncThunk(
  'members/removeMember',
  async (
    { circleId, memberId }: { circleId: string; memberId: string },
    { rejectWithValue }
  ) => {
    try {
      await circleApi.deleteMember(circleId, memberId);
      return memberId;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMembersOfCircle.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getMembersOfCircle.fulfilled,
        (state, action: PayloadAction<Member[]>) => {
          state.loading = false;
          state.members = action.payload;
        }
      )
      .addCase(getMembersOfCircle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(
        inviteMember.fulfilled,
        (state, action: PayloadAction<Member>) => {
          state.members.push(action.payload);
        }
      )
      .addCase(
        removeMember.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.members = state.members.filter(
            (member) => member._id !== action.payload
          );
        }
      );
  },
});

// Selector to access members state
export const selectMembers = (state: RootState) => state.members.members;

export default membersSlice.reducer;

import React, { FC, useState, useEffect } from 'react';
import { CommentsSideProps } from 'src/pages/UserCircle/conts';

import styles from './CommentsSide.module.scss';
import { PostCommentInput } from './PostCommentInput/PostCommentInput';
import { CommentItem } from './CommetItem/CommentItem';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';
import { Comment } from '../const';

export const CommentsSide: FC<CommentsSideProps> = ({
  commentsData,
  videoId,
  loading,
}) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [children, setChildren] = useState<Comment[][]>([]);

  useEffect(() => {
    setComments(commentsData.result);
    setChildren(commentsData.children);
  }, [commentsData]);

  const handleNewComment = (newComment: Comment) => {
    setComments((prevComments) => [newComment, ...prevComments]);
    setChildren((prevChildren) => {
      const firstChildArray = prevChildren[0] || [];
      return [[...firstChildArray], ...prevChildren];
    });
  };

  const handleDeleteComment = (commentId: string) => {
    setComments((prevComments) =>
      prevComments.filter((comment) => comment._id !== commentId)
    );
    setChildren((prevChildren) =>
      prevChildren.filter((_, index) => comments[index]._id !== commentId)
    );
  };

  const handleEditComment = (commentId: string, newText: string) => {
    setComments((prevComments) =>
      prevComments.map((comment) =>
        comment._id === commentId ? { ...comment, text: newText } : comment
      )
    );
  };

  if (loading) {
    return (
      <div>
        <MaterialCircularPogress />
      </div>
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <SmsOutlinedIcon sx={{ color: 'black' }} />
        <span className={styles.totalComments}>
          Total Comments: {comments.length}
        </span>
      </div>
      <div className={styles.commentsList}>
        {comments.map((comment: Comment, index: number) => (
          <CommentItem
            comment={comment}
            key={comment._id}
            replies={children[index] || []}
            videoId={videoId}
            onDelete={handleDeleteComment}
            onEdit={handleEditComment}
          />
        ))}
      </div>
      <div className={styles.replyBlock}>
        <PostCommentInput
          videoId={videoId}
          onCommentPosted={handleNewComment}
        />
      </div>
    </div>
  );
};

import { SectionComponent } from '../SectionCompnent/SectionCompnent';
import {
  WHAT_DOES_IT_ENTAIL,
  CREATING_CIRCLE,
  PURPOSE_OF_CREATING_CIRCLE,
  WHO_SHOUD_CREATE_CIRCLE,
  ONGOING_RESPONS,
} from '../const';
import styles from './Circle.module.scss';

export const Circle = () => {
  return (
    <div className={styles.container}>
      <div id="info-circle-creation">
        <SectionComponent
          title={CREATING_CIRCLE.title}
          description={CREATING_CIRCLE.description}
        />
      </div>
      <div id="info-circle-purpose">
        <SectionComponent
          isSmallTitle
          title={PURPOSE_OF_CREATING_CIRCLE.title}
          description={PURPOSE_OF_CREATING_CIRCLE.description}
        />
      </div>
      <div id="info-circle-who-shoud">
        <SectionComponent
          isSmallTitle
          title={WHO_SHOUD_CREATE_CIRCLE.title}
          description={WHO_SHOUD_CREATE_CIRCLE.description}
        />
      </div>
      <div id="info-circle-entail">
        <SectionComponent
          isSmallTitle
          title={WHAT_DOES_IT_ENTAIL.title}
          description={WHAT_DOES_IT_ENTAIL.description}
        />
      </div>
      <div id="info-circle-responsibilities">
        <SectionComponent
          isSmallTitle
          title={ONGOING_RESPONS.title}
          description={ONGOING_RESPONS.description}
        />
      </div>
    </div>
  );
};

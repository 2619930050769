import { FC } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { SelectChangeEvent } from '@mui/material/Select';
import { MuiSelect } from 'src/components/material/MuiSelect';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styles from './Members.module.scss';

type SelectOption = {
  value: string;
  label: string;
};

const MEMBER_OPTIONS: SelectOption[] = Array.from({ length: 148 }, (_, i) => ({
  value: (i + 1).toString(),
  label: (i + 1).toString(),
}));

interface MembersProps {
  value: number;
  setValue: (event: SelectChangeEvent) => void;
}

export const Members: FC<MembersProps> = ({ value, setValue }) => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.title}>Members</div>
        <Link to={`/how-it-works#info-members-info`}>
          <InfoOutlinedIcon style={{ color: 'red', cursor: 'pointer' }} />
        </Link>
      </div>
      <div className={styles.description}>
        Set the maximum number of Members you wish to admit to your Circle now.
        You can Admit more Members Later.
      </div>
      <div className={styles.wrapper}>
        <MuiSelect
          label="Members"
          value={value.toString()}
          options={MEMBER_OPTIONS}
          onChange={setValue}
        />
        <div className={styles.titleSelect}>Members</div>
      </div>
      <div className={styles.charactersLength}>
        Choose a number from 1 to 148
      </div>
    </div>
  );
};

export default Members;

import styles from './Videos.module.scss';
import { VideoItem } from './VideoItem/VideoItem';
import { VideosProps } from '../../conts';

export const Videos = ({ postData }: VideosProps) => {
  return (
    <div className={styles.scrollableContainer}>
      {postData && postData.length > 0 ? (
        postData.map((item) => <VideoItem item={item} key={item._id} />)
      ) : (
        <div>No videos available for this circle</div>
      )}
    </div>
  );
};

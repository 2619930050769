export const FAQS = [
  {
    question: 'What is a Member, a Leader, and a Circle?',
    answer: (
      <>
        <p>
          <span>Member:</span> A Member is an individual who joins a Circle to
          pursue personal or professional growth. Members participate in daily
          Challenges, share their progress, and engage with others in the Circle
          to create a supportive and motivating community.
        </p>
        <p>Circle to create a supportive and motivating community.;</p>
        <p>
          <span> Leader:</span> A Leader is a mentor or guide within Dunbar who
          creates and manages a Circle. Leaders share their expertise,
          experiences, and insights through daily Challenges and provide
          motivational content to help Members achieve their goals.
        </p>
        <p>
          <span>Circle:</span> A Circle is a small, focused community within the
          Dunbar platform where Members come together to pursue common goals and
          interests. Each Circle is led by a Leader and is limited to 148
          individuals to ensure meaningful interactions and a strong sense of
          community. Members participate in daily micro-learning activities,
          share experiences, and offer support to one another.
        </p>
      </>
    ),
  },
  {
    question: 'What are Challenges?',
    answer: (
      <>
        <p>
          Challenges in a Circle are daily activities or tasks set by Leaders to
          help Members achieve their personal and professional goals. These
          challenges are designed to be simple, manageable, and impactful,
          encouraging Members to take small, consistent steps that lead to
          significant growth over time. Examples include quick exercises,
          reflective journaling prompts, or new skills to practice​​.
        </p>
      </>
    ),
  },
  {
    question: 'How do I join a Circle?',
    answer: (
      <>
        <p>
          To join a Circle, you sign up for a subscription to that Circle
          through the Grand Auction or a Mini Auction by placing a bid at the
          pre-set price. If your bid is successful, you secure a spot in the
          Circle. If not, you are placed on a Waitlist for future openings. Once
          you join a Circle, you gain access to the daily Challenges, community
          discussions, and support from the Leader and other Members​​.
        </p>
      </>
    ),
  },
  {
    question: 'What is the Dunbar auction subscription model?',
    answer: (
      <>
        <p>
          The Dunbar auction subscription model allows Members to bid for spots
          in exclusive Circles led by leaders in various fields. This dynamic
          and competitive approach ensures that the most dedicated and
          enthusiastic Members can secure their places in the Circles they are
          passionate about.
        </p>
      </>
    ),
  },
  {
    question: 'What is the Grand Auction?',
    answer: (
      <>
        <p>
          The Grand Auction is a 7-day event during which all spots in a new
          Circle are made available for bidding. Members place bids to secure
          their places in the Circle, and the highest bidders win the spots. If
          there are more bids at the same price level than available spots,
          priority is given to the earliest bids. Unsuccessful bidders are
          placed on a Waitlist for future openings.
        </p>
      </>
    ),
  },
  {
    question:
      'What is a Mini Auction and how does it differ from the Grand Auction?',
    answer: (
      <>
        <p>
          A Mini Auction is a 24-hour event where additional spots in a Circle
          are made available, either periodically or in response to increased
          demand. This auction works similarly to the Grand Auction but is
          shorter and notifies everyone on the Waitlist when it is launched​​.
        </p>
      </>
    ),
  },
  {
    question: 'What happens if I am outbid during the auction?',
    answer: (
      <>
        <p>
          If you are outbid during the auction, you do not pay anything and are
          placed on a Waitlist. You can try again, as many times as you want,
          within the 7-day period of the Grand Auction or wait for a Mini
          Auction to secure your spot​​.
        </p>
      </>
    ),
  },
  {
    question: 'How is the subscription price determined in the auction?',
    answer: (
      <>
        <p>
          The subscription price is pre-set in fixed increments by Dunbar.
          Members do not set their own prices but bid at the listed price. The
          price to join a Circle is what you see listed when you join, and you
          only pay if you secure a spot after the 7-day period and are not
          outbid​​.
        </p>
      </>
    ),
  },
  {
    question:
      'What are the pre-set fixed increments for the subscription price during the auction?',
    answer: (
      <>
        <p>
          The price points are $10, $25, $50, $100, $250, $500, $1000, and
          $2500. Initial subscription prices start at $10 for all Circles. If
          the Circle does not fill up, the price stays at $10. If more people
          want to join than there are spots available, the price jumps to $25
          once all the spots are filled. If all the spots are filled with
          Members willing to pay $25/month, the price jumps to $50, and so
          forth. As a Member, your subscription fee will never change from what
          you first committed to when joining.
        </p>
      </>
    ),
  },
  {
    question: 'Can I increase the bid amount or choose the bid amount myself?',
    answer: (
      <>
        <p>
          No, members cannot choose or increase the bid amount themselves. The
          subscription price is pre-set in fixed increments by Dunbar, and
          Members bid at the listed price. The price to join a Circle is what is
          displayed during the auction, and members pay this amount if they
          secure a spot.
        </p>
      </>
    ),
  },
  {
    question:
      'What are the benefits of joining a Circle early during the auction?',
    answer: (
      <>
        <p>
          Early bids are prioritized if there are more bids at the same price
          level than available spots. This means that joining early increases
          your chances of securing a spot at a lower price in the Circle​​.
        </p>
      </>
    ),
  },
  {
    question:
      'What is the purpose of the Waitlist in the Dunbar auction system?',
    answer: (
      <>
        <p>
          The Waitlist is for individuals who bid for a spot but were not
          initially successful. It ensures that as soon as a spot becomes
          available, those on the Waitlist are notified and given 24 hours to
          join. Being on the Waitlist is free​​.
        </p>
      </>
    ),
  },
  {
    question:
      'Will my subscription fee change after I secure a spot in a Circle?',
    answer: (
      <>
        <p>
          No, once you are granted access to a Circle, your subscription fee
          remains constant from what you initially committed to, even if the
          price increases for new Members joining after you​​.
        </p>
      </>
    ),
  },
  {
    question:
      'Can I lose my spot in a Circle if new Members are willing to pay more?',
    answer: (
      <>
        <p>
          No, once you are granted access to a Circle and the auction you
          participated in is concluded, your spot in the Circle is secured, even
          if the price increases for new Members joining after you​​.
        </p>
      </>
    ),
  },
  {
    question: 'Can I end my subscription at any time?',
    answer: (
      <>
        <p>
          Yes, subscriptions are month-to-month, and Members can end their
          subscription at any time without additional charges. They will retain
          access to the Circle until the end of their current monthly billing
          period​​.
        </p>
      </>
    ),
  },
  {
    question: 'Can I join more than one Circle?',
    answer: (
      <>
        <p>
          Yes, you can join multiple Circles if you are interested in pursuing
          different goals or interests. Each Circle has its own Leader, daily
          Challenges, and community discussions. However, you will need to
          participate in the auction process for each Circle you wish to join​​.
        </p>
      </>
    ),
  },
];

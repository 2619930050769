export const PathRoutes = {
  HomeRoute: '/',
  LoginRoute: '/login',
  SignUpRoute: '/sign-up',
  ForgetPasswordRoute: '/forget-password',
  AuctionRoute: '/auction',
  CreateProfileRoute: '/create-profile',
  EditProfileRoute: '/edit-profile',
  CreateCircleRoute: '/create-circle',
  EditCircleRoute: '/edit-circle/:id',
  HowItWorks: '/how-it-works',
  UserCircle: '/circle/:id',
  AdminPageRoute: '/admin',
  UserPageRoute: '/user/:id',
  FeedPageRoute: 'feed',
};

import screenOne from 'src/assets/images/howToPage/Picture13.png';
import screenTwo from 'src/assets/images/howToPage/Picture14.png';

export const FIRST_BLOCK = {
  title:
    'The main screen in the Dunbar app is your feed, displaying the Challenges issued across any and all Circles of which you are a Member (or Leader). ',
  img: screenOne,
  description: [
    <>
      <strong>To browse previous Challenges</strong>, swipe vertically to scroll
      through the list of available Challenges.{' '}
    </>,
    <>
      <strong>To view a Challenge’s forum</strong>, tap the Dunbar icon in the
      bottom-center.{' '}
    </>,
  ],
};

export const SECOND_BLOCK = {
  title:
    'If you see the “no posts” screen (left), you have not yet been added to a Circle. Contact your Circle Leader(s) or your learning support team to address the issue. ',
  img: screenTwo,
};

import { FC, useState, useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { MuiSelect } from 'src/components/material/MuiSelect';
import { MONTH_OPTIONS } from './const';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styles from './Birthday.module.scss';
import { useNavigate } from 'react-router-dom';

const dayOptions = Array.from({ length: 31 }, (_, index) => ({
  value: index + 1,
  label: (index + 1).toString(),
}));

const yearOptions = Array.from({ length: 100 }, (_, index) => ({
  value: new Date().getFullYear() - index,
  label: (new Date().getFullYear() - index).toString(),
}));

interface BirthdayProps {
  value: string;
  setValue: (value: string) => void;
}

export const Birthday: FC<BirthdayProps> = ({ value, setValue }) => {
  const [localMonth, setLocalMonth] = useState<string>('');
  const [localDay, setLocalDay] = useState<string>('');
  const [localYear, setLocalYear] = useState<string>('');

  useEffect(() => {
    if (value) {
      const [y, m, d] = value.split('-');
      setLocalYear(y);
      setLocalMonth(m);
      setLocalDay(d);
    }
  }, [value]);

  useEffect(() => {
    if (localDay && localMonth && localYear) {
      setValue(`${localYear}-${localMonth}-${localDay}`);
    }
  }, [localDay, localMonth, localYear, setValue]);
  // TO-DO remove in future setValue in deps of useEffect

  const handleMonthChange = (event: SelectChangeEvent) => {
    setLocalMonth(event.target.value as string);
  };

  const handleDayChange = (event: SelectChangeEvent) => {
    setLocalDay(event.target.value as string);
  };

  const handleYearChange = (event: SelectChangeEvent) => {
    setLocalYear(event.target.value as string);
  };

  const navigate = useNavigate();

  const handleIconClick = () => {
    navigate(`/how-it-works#info-birthday`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.title}>Birthday</div>
        <div onClick={handleIconClick}>
          <InfoOutlinedIcon style={{ color: 'red', cursor: 'pointer' }} />
        </div>
      </div>
      <div className={styles.description}>
        We ask for birthday information to help ensure that underage people
        aren't using Dunbar.
      </div>
      <div className={styles.wrapper}>
        <MuiSelect
          label="Month"
          value={localMonth}
          options={MONTH_OPTIONS}
          onChange={handleMonthChange}
        />
        <MuiSelect
          label="Day"
          value={localDay}
          options={dayOptions}
          onChange={handleDayChange}
        />
        <MuiSelect
          label="Year"
          value={localYear}
          options={yearOptions}
          onChange={handleYearChange}
        />
      </div>
      <div className={styles.description}>
        Your birthyear is confidential and not shared with anyone.
      </div>
    </div>
  );
};

export default Birthday;

import React, { FC, useState, useEffect } from 'react';
import { MuiModal } from 'src/components/material/MuiModal';
import { DatePicker } from '@mui/x-date-pickers';
import { Moment } from 'moment';
import { Button, TextField, Typography } from '@mui/material';
import styles from './CreateCouponsModal.module.scss';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import { createCoupon } from 'src/redux/slices/coupons/couponsSlice';

interface CreateCouponsModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (open: boolean) => void;
}

export const CreateCouponsModal: FC<CreateCouponsModalProps> = ({
  isOpenModal,
  setIsOpenModal,
}) => {
  const dispatch = useAppDispatch();
  const [client, setClient] = useState<string>('');
  const [maxUser, setMaxUser] = useState<number | ''>('');
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [couponGenerated, setCouponGenerated] = useState<boolean>(false);
  const [generatedCouponCode, setGeneratedCouponCode] = useState<string>('');

  // Helper function to format the code
  const formatCouponCode = (code: string) => {
    return code.match(/.{1,3}/g)?.join('-') || code;
  };

  const handleGenerateCoupon = async () => {
    if (client && maxUser && startDate && endDate) {
      const newCoupon = await dispatch(
        createCoupon({
          client,
          maxUses: Number(maxUser),
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
        })
      ).unwrap();
      setCouponGenerated(true);
      setGeneratedCouponCode(formatCouponCode(newCoupon.code));
    } else {
      alert('Please fill in all fields to generate a coupon');
    }
  };

  // Reset the form fields and state when the modal is closed
  useEffect(() => {
    if (!isOpenModal) {
      setClient('');
      setMaxUser('');
      setStartDate(null);
      setEndDate(null);
      setCouponGenerated(false);
      setGeneratedCouponCode('');
    }
  }, [isOpenModal]);

  return (
    <MuiModal open={isOpenModal} setOpen={setIsOpenModal} width={300}>
      <div className={styles.container}>
        {/* Form Fields */}
        <TextField
          label="Client"
          variant="outlined"
          value={client}
          onChange={(e) => setClient(e.target.value)}
          sx={{ width: '232px' }}
        />
        <TextField
          label="Max Users"
          variant="outlined"
          type="number"
          value={maxUser}
          onChange={(e) => setMaxUser(Number(e.target.value) || '')}
          sx={{ width: '232px' }}
        />
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
        />
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2, width: '232px' }}
          size="large"
          onClick={handleGenerateCoupon}
        >
          Generate Coupon
        </Button>
        {couponGenerated && (
          <Typography variant="h6" sx={{ mt: 2, fontSize: 17 }}>
            Here is your coupon: {generatedCouponCode}
          </Typography>
        )}
      </div>
    </MuiModal>
  );
};

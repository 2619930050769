import { BlockWithImg } from 'src/components/common/HowToPageComponents/BlockWIthImg/BlockWIthImg';
import screenOne from 'src/assets/images/howToPage/Picture15.png';
import screenTwo from 'src/assets/images/howToPage/Picture16.png';
import screenThree from 'src/assets/images/howToPage/Picture17.png';
import styles from './ProfileAndMessages.module.scss';

export const ProfileAndMessages = () => {
  return (
    <div>
      <div className={styles.blockTitle}>5- Profile and messages</div>
      <div className={styles.container}>
        <BlockWithImg
          src={screenOne}
          title="5.1 From your Feed, tap your avatar to open the user menu."
        />
        <BlockWithImg
          src={screenTwo}
          title="5.2 In the menu tap the View button next to “My Profile”."
        />
        <BlockWithImg
          src={screenThree}
          title={
            <>
              5.3.1. In your profile screen you can see{' '}
              <strong>Incoming</strong> messages (sent to you) and{' '}
              <strong>Outgoing</strong> replies you previously sent. "
            </>
          }
          bottomTitle={
            <>
              5.3.2 Tap the pencil icon to view and{' '}
              <strong>edit your profile details.</strong> "
            </>
          }
        />
      </div>
    </div>
  );
};

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { themeColors, themeTypography } from './theme';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  // useParams,
} from 'react-router-dom';
import { Login } from 'src/pages/Login/Login';
import { Home } from 'src/pages/Home/Home';
import './amplifyconfiguration';
import useUserSession from 'src/hooks/UserSession/UserSession';
import ProtectedRoute from './wrappers/ProtectedRoute';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';
import MainTemplate from './templates/Main';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import { SignUp } from 'src/pages/SignUp/SignUp';
import { ForgetPassword } from 'src/pages/ForgetPassword/ForgetPassword';
import { Sidebar } from './components/common/Sidebar/Sidebar';
import { Auction } from './pages/Auction/Auction';
import { CreateProfile } from './pages/CreateProfile/CreateProfile';
import { CreateCircle } from './pages/CreateCircle/CreateCircle';
import { HowItWorks } from './pages/HowItWorks/HowItWorks';

import { useEffect } from 'react';
import { UserCircle } from './pages/UserCircle/UserCircle';
import { getUserId } from './redux/slices/userSlice';
import { useAppDispatch } from './hooks/Redux/useAppDispatch';
import { EditProfile } from './pages/EditProfile/EditProfile';
import { EditCircle } from './pages/EditCircle/EditCircle';
import { AdminPage } from './pages/AdminPage/AdminPage';
import { UserPage } from './pages/UserPage/UserPage';
import { FeedPage } from './pages/FeedPage/FeedPage';
import useAppSelector from './hooks/Redux/useAppSelector';
import { selectCurrentUser } from './redux/slices/loggedUserSlice';

const theme = createTheme({
  palette: { ...themeColors },
  typography: { ...themeTypography },
});

export const App = () => {
  const { loading, user } = useUserSession();
  const location = useLocation();
  // const userId = useAppSelector(getCurrentUserId);
  const dispatch = useAppDispatch();
  const logginedUser = useAppSelector(selectCurrentUser);
  console.log(logginedUser, 'logginedUser');
  const shouldDisplaySidebar = ![
    PathRoutes.LoginRoute,
    PathRoutes.SignUpRoute,
    PathRoutes.ForgetPasswordRoute,
    PathRoutes.CreateProfileRoute,
    PathRoutes.CreateCircleRoute,
    PathRoutes.EditProfileRoute,
    PathRoutes.EditCircleRoute,
  ].includes(location.pathname);

  useEffect(() => {
    dispatch(getUserId());
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {shouldDisplaySidebar && <Sidebar />}
      {loading ? (
        <MaterialCircularPogress />
      ) : (
        <Routes>
          <Route element={<ProtectedRoute user={user} />}>
            <Route element={<MainTemplate />}>
              <Route path={PathRoutes.HomeRoute} element={<Home />} />
            </Route>
          </Route>
          <Route element={<MainTemplate />}>
            <Route path={PathRoutes.AuctionRoute} element={<Auction />} />
          </Route>
          <Route element={<MainTemplate />}>
            <Route path={PathRoutes.FeedPageRoute} element={<FeedPage />} />
          </Route>
          <Route element={<MainTemplate />}>
            <Route path={PathRoutes.AdminPageRoute} element={<AdminPage />} />
          </Route>
          <Route element={<MainTemplate />}>
            <Route path={PathRoutes.UserPageRoute} element={<UserPage />} />
          </Route>
          <Route element={<MainTemplate />}>
            <Route path={PathRoutes.UserCircle} element={<UserCircle />} />
          </Route>
          <Route element={<MainTemplate />}>
            <Route path={PathRoutes.HowItWorks} element={<HowItWorks />} />
          </Route>
          <Route
            path={PathRoutes.LoginRoute}
            element={user ? <Navigate to={PathRoutes.HomeRoute} /> : <Login />}
          />
          <Route path={PathRoutes.SignUpRoute} element={<SignUp />} />
          <Route
            path={PathRoutes.ForgetPasswordRoute}
            element={<ForgetPassword />}
          />
          <Route
            path={PathRoutes.CreateProfileRoute}
            element={<CreateProfile />}
          />
          <Route path={PathRoutes.EditProfileRoute} element={<EditProfile />} />
          <Route
            path={PathRoutes.CreateCircleRoute}
            element={<CreateCircle />}
          />
          <Route path={PathRoutes.EditCircleRoute} element={<EditCircle />} />
        </Routes>
      )}
    </ThemeProvider>
  );
};

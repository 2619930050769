import { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useUserLoginLogout } from 'src/hooks/UserLoginLogout/UserLoginLogout';
// import AccountCircle from '@mui/icons-material/AccountCircle';
// import LogoutIcon from '@mui/icons-material/Logout';
// import ProfileIcon from '@mui/icons-material/PersonOutline';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import logo from 'src/assets/images/logos/logo-with-text_dark.png';
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
  AppBar,
  Box,
  // Divider,
  // IconButton,
  // ListItemIcon,
  // ListItemText,
  // Menu,
  // MenuItem,
  Toolbar,
  // useTheme,
  useMediaQuery,
  Button,
} from '@mui/material';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import { currentUser } from 'src/redux/slices/userSlice';
import { MuiModal } from 'src/components/material/MuiModal';
import { RegisterModal } from 'src/components/common/Sidebar/RegisterModal/RegisterModal';
import { CreatePost } from 'src/components/common/Modals/CreatePost/CreatePost';
import styles from './Main.module.scss';

const MainTemplate: FC = () => {
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenCreatePostModal, setIsOpenCreatePostModal] = useState(false);
  // const theme = useTheme();
  const { logout } = useUserLoginLogout();
  const isSmallScreen = useMediaQuery('(max-width:545px)');
  const user = useAppSelector(currentUser);

  // const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleSignOut = async () => {
    await logout();
    // handleClose();
  };

  return (
    <Box sx={{ flexGrow: 1, marginLeft: isSmallScreen ? '57px' : '240px' }}>
      <AppBar
        color="transparent"
        position="fixed"
        sx={{
          zIndex: 10001,
          left: 0,
          boxShadow: 'none',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Toolbar sx={{ background: 'white' }}>
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
            <img className={styles.logo} src={logo} alt="logo-with-text" />
          </Box>
          {/* {user && (
            <Button
              type="submit"
              variant="contained"
              onClick={() => setIsOpenCreatePostModal(true)}
              className={styles.btn}
            >
              <AddOutlinedIcon sx={{ fontSize: '20px' }} /> New Post
            </Button>
          )} */}
          <CreatePost
            isOpenModal={isOpenCreatePostModal}
            setIsOpenModal={setIsOpenCreatePostModal}
          />
          <Box className="profile-icon-container">
            {user ? (
              <LogoutOutlinedIcon
                onClick={handleSignOut}
                sx={{ color: 'black', fontSize: '25px', cursor: 'pointer' }}
              />
            ) : (
              <Button
                type="submit"
                variant="contained"
                onClick={() => setIsOpenModal(true)}
              >
                Log in
              </Button>
            )}
            <MuiModal open={isOpenModal} setOpen={setIsOpenModal}>
              <RegisterModal />
            </MuiModal>
            {/* <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            > */}
            {/* <MenuItem
                sx={{ color: theme.palette.secondary.main }}
                onClick={handleClose}
              >
                <ListItemIcon>
                  <ProfileIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Account Information</ListItemText>
              </MenuItem> */}
            {/* <Divider /> */}
            {/* <MenuItem
              sx={{ color: theme.palette.secondary.main }}
              onClick={handleSignOut}
              disabled={loading}
            >
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText sx={{ color: 'black' }}>Sign out</ListItemText>
            </MenuItem> */}
            {/* {error && (
                <MenuItem disabled>
                  <ListItemText>{error}</ListItemText>
                </MenuItem>
              )}
            </Menu> */}
          </Box>
        </Toolbar>
      </AppBar>
      <Outlet />
    </Box>
  );
};

export default MainTemplate;

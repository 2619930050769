import styles from './Header.module.scss';

export const Header = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Create profile</div>
      <div className={styles.desc}>
        Everything below can be changed at any time later, too.
      </div>
    </div>
  );
};

import { ChangeEvent, FC, useState, useCallback, useEffect } from 'react';
import { CommonField } from './CommonField/CommonField';
import { Header } from './Header/Header';
import { UploadVideo } from 'src/components/common/Video/Video';
import styles from './CreateCircle.module.scss';
import { DESCRIPTION_FIELD, NAME_FIELD } from './const';
import { Button, Typography } from '@mui/material';
import Habits from 'src/components/common/Habits/Habits';
// import { Subscription } from './Subscription/Subscription';
import { ChooseCategory } from './Category/Category';
import Members from './Members/Members';
import { circleApi } from 'src/api/circles';
import { SelectChangeEvent } from '@mui/material/Select';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import { getCurrentUserId } from 'src/redux/slices/userSlice';
import { usersApi } from 'src/api/user';
import { ApplyCoupon } from './ApplyCoupon/ApplyCoupon';
import { ProceedModal } from './ProceedModal/ProceedModal';
import { adminPanelApi } from 'src/api/adminPanel';
import { useNavigate } from 'react-router-dom';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import { UploadImage } from 'src/components/common/UploadIamge/UploadImage';

export interface FormData {
  leader_name: string;
  leader_avatar_url: string;
  tribe_name: string;
  leader_id: string;
  categories: string[];
  welcome_video_id: string;
  description: string;
  member_count_max: number;
  habits: { name: string; category: string }[];
  membership_type: string;
  price: number;
  coupon_code?: string;
  tribe_avatar_url: string;
}

interface Coupon {
  _id: string;
  client: string;
  code: string;
  status: string;
  createdAt: string;
  endDate: string;
  max_users: number;
  startDate: string;
  tribes: string[];
  updatedAt: string;
  used_count: number;
  __v: number;
}

interface GetCouponByCodeResponse {
  coupon: Coupon;
}

export const CreateCircle: FC = () => {
  const userId = useAppSelector(getCurrentUserId);

  const [seats, setSeats] = useState(0);
  const [seatsAnother, setSeatsAnother] = useState(0);

  const [formData, setFormData] = useState<FormData>({
    leader_name: '',
    leader_avatar_url: '',
    tribe_name: '',
    categories: [],
    welcome_video_id: '',
    description: '',
    member_count_max: 1,
    habits: [],
    membership_type: 'FIXED',
    price: 10,
    leader_id: userId || '',
    coupon_code: '',
    tribe_avatar_url: '',
  });

  const [isOpenModal, setIsOpenModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    usersApi.userMe().then((res) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        leader_name: res.data.result.full_name,
        leader_avatar_url: res.data.result.avatar_url,
      }));
    });
  }, []);

  const [error, setError] = useState<string>('');

  const handleInputChange = useCallback(
    (field: keyof FormData) =>
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData((prevData) => ({
          ...prevData,
          [field]: event.target.value,
        }));
      },
    []
  );

  const handleImageUpdate = (fileId: string | File) => {
    if (typeof fileId === 'string') {
      setFormData({ ...formData, tribe_avatar_url: fileId });
    } else {
      console.log('Received a File object:', fileId);
    }
  };

  const handleSelectChange = useCallback(
    (field: keyof FormData) => (event: SelectChangeEvent) => {
      setFormData((prevData) => ({
        ...prevData,
        [field]: Number(event.target.value),
      }));
    },
    []
  );

  const handleCategoryClick = useCallback((category: string) => {
    setFormData((prevData) => {
      const isSelected = prevData.categories.includes(category);
      let updatedCategories: string[] = [];

      if (isSelected) {
        updatedCategories = prevData.categories.filter(
          (cat) => cat !== category
        );
      } else if (prevData.categories.length < 4) {
        updatedCategories = [...prevData.categories, category];
      } else {
        updatedCategories = prevData.categories;
      }

      return {
        ...prevData,
        categories: updatedCategories,
      };
    });
  }, []);

  // const handleSubscriptionChange = useCallback((type: string) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     membership_type: type,
  //   }));
  // }, []);

  const handleHabitsChange = useCallback(
    (newHabits: { id?: string; name: string; category: string }[]) => {
      const habitsWithoutId = newHabits.map(({ name, category }) => ({
        name,
        category,
      }));
      setFormData((prevData) => ({
        ...prevData,
        habits: habitsWithoutId,
      }));
    },
    []
  );

  const validateForm = useCallback((): boolean => {
    const {
      tribe_name,
      categories,
      welcome_video_id,
      description,
      member_count_max,
      habits,
    } = formData;

    if (
      !tribe_name ||
      categories.length === 0 ||
      !welcome_video_id ||
      !description ||
      member_count_max === 0 ||
      habits.length === 0
    ) {
      setError('All fields are required');
      return false;
    }

    setError('');
    return true;
  }, [formData]);

  const createCircle = useCallback(() => {
    if (!validateForm()) return;
    if (formData.coupon_code?.length === 12) {
      adminPanelApi
        .getCouponsByCode(formData.coupon_code)
        .then((res) => {
          const data: GetCouponByCodeResponse = res.data;
          setSeats(data.coupon.max_users || 0);
          setSeatsAnother(data.coupon.used_count || 0);
          if (data.coupon.max_users < formData.member_count_max) {
            setIsOpenModal(true);
          } else {
            circleApi
              .createCircle(formData)
              .then((res) => {
                navigate(PathRoutes.HomeRoute);
              })
              .catch((error) => {
                console.error(
                  'Error fetching coupon:',
                  error.response?.data || error.message
                );
                if (error.response?.data?.message === 'Coupon is maxed out') {
                  setIsOpenModal(true);
                }
              });
          }
        })
        .catch((error) => {
          console.error('Error fetching coupon:', error);
        });
    } else {
      circleApi.createCircle(formData).then((res) => {
        window.location.href = res.data.session_url;
      });
    }
  }, [formData, validateForm, navigate]);

  const handleVideoUpdate = (fileId: string | File) => {
    if (typeof fileId === 'string') {
      setFormData({ ...formData, welcome_video_id: fileId });
    } else {
      console.log('Received a File object:', fileId);
    }
  };

  // useEffect(() => {
  //   console.log(formData.coupon?.length, 'formData.coupon?.length');
  //   if (formData.coupon?.length === 12) {
  //     adminPanelApi
  //       .getCouponsByCode(formData.coupon)
  //       .then((res) => {
  //         const data: GetCouponByCodeResponse = res.data;
  //         if (data.coupon.max_users < formData.member_count_max) {
  //           setIsOpenModal(true);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching coupon:', error);
  //       });
  //   }
  // }, [formData.coupon]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Header />
        <CommonField
          title={NAME_FIELD.title}
          description={NAME_FIELD.description}
          placeholder={NAME_FIELD.placeholder}
          charactersLength={NAME_FIELD.charactersLength}
          anhor={'info-circle-name'}
          value={formData.tribe_name}
          setValue={handleInputChange('tribe_name')}
        />
        <ChooseCategory
          selectedCategory={formData.categories}
          handleCategoryClick={handleCategoryClick}
        />
        <UploadVideo
          defaultVideo={''}
          onUpdate={handleVideoUpdate}
          helperText="Upload a video (max 60 seconds)"
        />
        <UploadImage
          onUpdate={handleImageUpdate}
          defaultImage={
            typeof formData.tribe_avatar_url === 'string'
              ? formData.tribe_avatar_url
              : ''
          }
        >
          Add Photo
        </UploadImage>
        <CommonField
          type="textarea"
          title={DESCRIPTION_FIELD.title}
          description={DESCRIPTION_FIELD.description}
          placeholder={DESCRIPTION_FIELD.placeholder}
          charactersLength={DESCRIPTION_FIELD.charactersLength}
          anhor={'info-description'}
          value={formData.description}
          setValue={handleInputChange('description')}
        />
        <Habits
          description="“Habits” describe the daily activities and general mindset that lead to success for your Circle’s purpose."
          onHabitsChange={handleHabitsChange}
        />
        <Members
          value={formData.member_count_max}
          setValue={handleSelectChange('member_count_max')}
        />
        {/* <Subscription
          subscriptionType={formData.membership_type}
          onSubscriptionChange={handleSubscriptionChange}
          membersCount={formData.member_count_max}
        /> */}
        <ApplyCoupon
          value={formData.coupon_code}
          setValue={handleInputChange('coupon_code')}
        />
        {error && (
          <Typography
            color="error"
            variant="body2"
            sx={{ mb: 1, mt: 1, textAlign: 'center' }}
          >
            {error}
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, textTransform: 'none', fontSize: '18px' }}
          size="large"
          onClick={createCircle}
        >
          Create Circle
        </Button>
        <ProceedModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          seats={seats}
          formData={formData}
          seatsAnother={seatsAnother}
        />
      </div>
    </div>
  );
};

import { Button } from '@mui/material';
import styles from './SubInfo.module.scss';
import { Link } from 'react-router-dom';
import { PathRoutes } from 'src/const/Routes/PathRoutes';

export const SubInfo = () => {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.cutdown}>17 hours, 22 minutes left</div>
        <div className={styles.spots}>30 spots available</div>
      </div>
      <Button
        className={styles['btn']}
        type="submit"
        sx={{ mt: 1, mb: 1, textTransform: 'none', fontSize: '14px' }}
        size="medium"
      >
        <Link to={PathRoutes.HowItWorks}>How it works</Link>
      </Button>
    </div>
  );
};

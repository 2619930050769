import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  signUp,
  confirmSignUp,
  type SignUpOutput,
  type ConfirmSignUpInput,
} from 'aws-amplify/auth';
import { Box } from '@mui/material';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import { Copyright } from 'src/components/material/Copyright';
import phoneScreen from 'src/assets/images/screen/phone-screen.jpeg';
import styles from './SignUp.module.scss';
import { Logo } from 'src/components/common/Logo/Logo';
import { IFormInput } from './const';
import { FirstStep } from './FirstStep/FirstStep';
import { SecondStep } from './SecondStep/SecondStep';

export const SignUp = () => {
  const [error, setError] = useState('');
  const [step, setStep] = useState(0);

  const formatPhoneNumber = (number: string) => {
    return number.replace(/\s+/g, '');
  };

  const handleSignUp: SubmitHandler<IFormInput> = async (data) => {
    try {
      const formattedPhoneNumber = formatPhoneNumber(data.phoneNumber);
      const output: SignUpOutput = await signUp({
        username: formattedPhoneNumber,
        password: data.password,
        options: {
          userAttributes: {
            phone_number: formattedPhoneNumber,
          },
        },
      });
      handleSignUpNextSteps(output);
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleSignUpNextSteps = (output: SignUpOutput) => {
    const { nextStep } = output;
    switch (nextStep.signUpStep) {
      case 'CONFIRM_SIGN_UP':
        setStep(2);
        break;
      case 'DONE':
        console.log('Sign up complete.');
        break;
      default:
        setError('Unexpected step');
    }
  };

  const handleConfirmSignUp: SubmitHandler<IFormInput> = async (data) => {
    try {
      const formattedPhoneNumber = formatPhoneNumber(data.phoneNumber);
      const input: ConfirmSignUpInput = {
        username: formattedPhoneNumber,
        confirmationCode: data.confirmationCode!,
      };
      await confirmSignUp(input);
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <Box
          sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo />
          <Box
            sx={{
              mt: 0,
              mb: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#191920',
              fontSize: '24px',
              fontWeight: '600',
            }}
          >
            Create account
          </Box>
          <Box
            sx={{
              mt: 1,
              mb: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#191920',
              gap: '2px',
              fontWeight: '500',
            }}
          >
            Already have an account ?
            <Link to={PathRoutes.LoginRoute} className={styles.link}>
              Log in
            </Link>
          </Box>
          {step === 1 && <FirstStep onSubmit={handleSignUp} error={error} />}
          {step === 2 && (
            <SecondStep onSubmit={handleConfirmSignUp} error={error} />
          )}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4, color: '#191920' }} />
      </div>
      <div className={styles.rightSide}>
        <img
          src={phoneScreen}
          className={styles.screenLogo}
          alt="phone screen"
        />
      </div>
    </div>
  );
};

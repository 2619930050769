import React, { ReactNode } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import cn from 'classnames';
import styles from './WarningBlock..module.scss';

export enum IconType {
  Positive = 'positive',
  Warning = 'warning',
}

interface WarningBlockProps {
  title: string | ReactNode;
  iconType: IconType;
}

export const WarningBlock: React.FC<WarningBlockProps> = ({
  title,
  iconType,
}) => {
  const containerClassName = cn(styles.container, {
    [styles.positive]: iconType === IconType.Positive,
    [styles.warning]: iconType === IconType.Warning,
  });

  return (
    <div className={containerClassName}>
      {iconType === IconType.Warning && <InfoOutlinedIcon />}
      {iconType === IconType.Positive && <CheckCircleOutlineIcon />}
      <div className={styles.title}>{title}</div>
    </div>
  );
};

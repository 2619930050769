import Picker from 'emoji-picker-react';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import { useState, useRef, useEffect, FC } from 'react';
import styles from './PostCommentInput.module.scss';
import { commentsApi } from 'src/api/comments';

interface PostCommentInputProps {
  videoId: string;
  commentId?: string;
  isReply?: boolean;
  onCommentPosted?: (newComment: any) => void;
}

export const PostCommentInput: FC<PostCommentInputProps> = ({
  videoId,
  commentId,
  isReply,
  onCommentPosted,
}) => {
  const [commentText, setCommentText] = useState('');
  const [isEmojiOpen, setIsEmojiOpen] = useState<boolean>(false);
  const emojiPickerRef = useRef<HTMLDivElement>(null);

  const toggleEmojiPicker = () => {
    setIsEmojiOpen((prev) => !prev);
  };

  const onEmojiClick = (emojiObject: any) => {
    setCommentText((prevText) => prevText + emojiObject.emoji);
    // setIsEmojiOpen(false);
  };

  const postComment = async () => {
    if (commentText.trim() === '') return;

    const data = {
      text: commentText,
      parentId: commentId,
    };

    try {
      let response;
      if (isReply && commentId) {
        response = await commentsApi.postReply(videoId, commentId, data);
      } else {
        response = await commentsApi.postComment(videoId, data);
      }

      setCommentText('');

      if (response && onCommentPosted) {
        onCommentPosted(response.data);
      }
    } catch (error) {
      console.error('Error posting the comment:', error);
    }
  };

  // Close emoji picker when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target as Node)
      ) {
        setIsEmojiOpen(false);
      }
    };

    if (isEmojiOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener when the component unmounts or emoji picker closes
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEmojiOpen]);

  return (
    <div className={styles.commentInputContainer}>
      <div className={styles.inputWrapper}>
        <input
          placeholder="Add a comment"
          className={styles.commentInput}
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
        />
        <SentimentSatisfiedOutlinedIcon
          onClick={toggleEmojiPicker}
          className={styles.emojiIcon}
        />
      </div>
      {isEmojiOpen && (
        <div ref={emojiPickerRef} className={styles.emojiPickerContainer}>
          <Picker onEmojiClick={onEmojiClick} />
        </div>
      )}
      <div className={styles.postButton} onClick={postComment}>
        Post
      </div>
    </div>
  );
};

import { useState } from 'react';
import {
  confirmResetPassword as amplifyConfirmResetPassword,
  ConfirmResetPasswordInput,
} from 'aws-amplify/auth';

export const useConfirmResetPassword = () => {
  const [code, setCode] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  const formatPhoneNumber = (number: string): string => {
    return number.replace(/\s+/g, '');
  };

  const confirmResetPassword = async (phoneNumber: string): Promise<void> => {
    try {
      const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
      const input: ConfirmResetPasswordInput = {
        username: formattedPhoneNumber,
        confirmationCode: code,
        newPassword,
      };
      await amplifyConfirmResetPassword(input);
    } catch (err: any) {
      setError(err.message);
      throw err;
    }
  };

  return {
    code,
    setCode,
    newPassword,
    setNewPassword,
    confirmResetPassword,
    error,
    setError,
  };
};

import { FC, ChangeEvent } from 'react';
import MaterialTextField from 'src/components/material/textField';
import styles from './ApplyCoupon.module.scss';

interface ApplyCouponProps {
  value?: string;
  setValue: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const ApplyCoupon: FC<ApplyCouponProps> = ({ value, setValue }) => {
  const formatCouponCode = (input: string) => {
    // Remove all non-alphanumeric characters
    const cleanedInput = input.replace(/[^a-zA-Z0-9]/g, '');
    // Add hyphens every 3 characters
    return cleanedInput.match(/.{1,3}/g)?.join('-') || '';
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;

    // Clean and format the input
    const cleanedInput = newValue.replace(/[^a-zA-Z0-9]/g, ''); // Remove hyphens
    const formattedValue = formatCouponCode(cleanedInput);

    // Truncate to max length (9 alphanumeric characters)
    const truncatedCleanedValue = cleanedInput.slice(0, 12);

    // Update the parent state with the raw value (without hyphens)
    setValue({
      ...event,
      target: { ...event.target, value: truncatedCleanedValue },
    });

    // Update the input field with the formatted value
    event.target.value = formattedValue;
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.title}>Special Coupon</div>
      </div>
      <div className={styles.description}>
        Use this coupon to get free seats for your circle.
      </div>
      <MaterialTextField
        variant="outlined"
        color="primary"
        placeholder={'Enter coupon code please'}
        value={formatCouponCode(value || '')} // Ensure displayed value is formatted
        onChange={handleInputChange}
      />
      <div className={styles.charactersLength}>
        {(value?.replace(/-/g, '') || '').length}/12
      </div>
    </div>
  );
};

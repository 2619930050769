import animals from 'src/assets/images/categories/animals_dark.png';
import art from 'src/assets/images/categories/art_dark.png';
import business from 'src/assets/images/categories/business_dark.png';
import coaching from 'src/assets/images/categories/coaching_dark.png';
import creativity from 'src/assets/images/categories/creativity_dark.png';
import design from 'src/assets/images/categories/design_dark.png';
import education from 'src/assets/images/categories/education_dark.png';
import finance from 'src/assets/images/categories/finance_dark.png';
import food from 'src/assets/images/categories/food_dark.png';
import games from 'src/assets/images/categories/games_dark.png';
import health from 'src/assets/images/categories/health_dark.png';
import hobbies from 'src/assets/images/categories/hobbies_dark.png';
import hr from 'src/assets/images/categories/hr_dark.png';
import leadership from 'src/assets/images/categories/leadership_dark.png';
import marketing from 'src/assets/images/categories/marketing_dark.png';
import mindfulness from 'src/assets/images/categories/mindfulness_dark.png';
import music from 'src/assets/images/categories/music_dark.png';
import nature from 'src/assets/images/categories/nature_dark.png';
import operations from 'src/assets/images/categories/operations_dark.png';
import productivity from 'src/assets/images/categories/productivity_dark.png';
import sales from 'src/assets/images/categories/sales_dark.png';
import spirituality from 'src/assets/images/categories/spirituality_dark.png';
import sports from 'src/assets/images/categories/sports_dark.png';
import style from 'src/assets/images/categories/style_dark.png';
import strategy from 'src/assets/images/categories/strategy_dark.png';
import support from 'src/assets/images/categories/support_dark.png';
import team from 'src/assets/images/categories/team_dark.png';
import technology from 'src/assets/images/categories/technology_dark.png';
import writing from 'src/assets/images/categories/writing_dark.png';
import other from 'src/assets/images/categories/other_dark.png';
import relationships from 'src/assets/images/categories/relationships_dark.png';

export const CATEGORIES = [
  {
    name: 'animals',
    img: animals,
  },
  {
    name: 'art',
    img: art,
  },
  {
    name: 'business',
    img: business,
  },
  {
    name: 'coaching',
    img: coaching,
  },
  {
    name: 'creativity',
    img: creativity,
  },
  {
    name: 'design',
    img: design,
  },
  {
    name: 'education',
    img: education,
  },
  {
    name: 'finance',
    img: finance,
  },
  {
    name: 'food',
    img: food,
  },
  {
    name: 'games',
    img: games,
  },
  {
    name: 'health',
    img: health,
  },
  {
    name: 'hobbies',
    img: hobbies,
  },
  {
    name: 'hr',
    img: hr,
  },

  {
    name: 'leadership',
    img: leadership,
  },
  {
    name: 'marketing',
    img: marketing,
  },
  {
    name: 'mindfulness',
    img: mindfulness,
  },
  {
    name: 'music',
    img: music,
  },
  {
    name: 'nature',
    img: nature,
  },
  {
    name: 'operations',
    img: operations,
  },
  {
    name: 'music',
    img: music,
  },

  {
    name: 'productivity',
    img: productivity,
  },
  {
    name: 'relationships',
    img: relationships,
  },
  {
    name: 'sales',
    img: sales,
  },
  {
    name: 'spirituality',
    img: spirituality,
  },
  {
    name: 'sports',
    img: sports,
  },
  {
    name: 'spirituality',
    img: spirituality,
  },
  {
    name: 'style',
    img: style,
  },

  {
    name: 'strategy',
    img: strategy,
  },
  {
    name: 'support',
    img: support,
  },
  {
    name: 'team',
    img: team,
  },
  {
    name: 'technology',
    img: technology,
  },
  {
    name: 'writing',
    img: writing,
  },
  {
    name: 'other',
    img: other,
  },
];

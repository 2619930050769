import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../slices/userSlice';
import loggedUserReducer from '../slices/loggedUserSlice';
import membersReducer from '../slices/members/Slice/membersSlice';
import couponsReducer from '../slices/coupons/couponsSlice';
import feedReducer from '../slices/feedSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    loggedUser: loggedUserReducer,
    members: membersReducer,
    coupons: couponsReducer,
    feed: feedReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

import { signIn, signOut, AuthError } from 'aws-amplify/auth';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PathRoutes } from 'src/const/Routes/PathRoutes';

interface UserLogin {
  username: string;
  password: string;
}

export const useUserLoginLogout = () => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const login = async ({ username, password }: UserLogin) => {
    setError(undefined);
    setLoading(true);
    try {
      const result = await signIn({
        username: username.replace(/\s/g, ''),
        password,
      });
      return { isSignedIn: !!result };
    } catch (e) {
      console.error('Login error:', e);
      if (e instanceof AuthError) {
        setError(e.message);
      } else {
        setError('Sorry, an unknown error occurred, please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setLoading(true);
    try {
      await signOut();
      navigate(PathRoutes.HomeRoute, { replace: true });
      navigate(0);
    } catch (error) {
      console.error('Error signing out', error);
      setError('Error signing out, please try again.');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    login,
    logout,
    error,
  };
};

import { InfoCircle } from '../InfoCircles/InfoCircles';
import { InfoProfile } from '../InfpProfile/InfoProfile';
import { ChallengeAndForum } from './ChallengeAndForum/ChallengeAndForum';
import { CommonIssue } from './CommonIssue/CommonIssue';
import { CreateProfile } from './CreaeteProfile/CreaeteProfile';
import { CreateAnAccount } from './CreateAnAccount/CreateAnAccount';
import { CreateChallnge } from './CreateChallenge/CreateChallenge';
import { CreateCircle } from './CreateCircle/CreateCircle';
import { DonwloadTheApp } from './DonwloadTheApp/DonwloadTheApp';
import { ForgotPssword } from './ForgotPassword/ForgotPassword';
import { LogIn } from './LogIn/LogIn';
import { ProfileAndMessages } from './ProfileAndMessages/ProfileAndMessages';
import { SignOut } from './SignOut/SignOut';

import styles from './UseTheApp.module.scss';

export const UseTheApp = () => {
  return (
    <div>
      <div id="use-the-app-donwload">
        <DonwloadTheApp />
      </div>
      <div id="use-the-app-create">
        <CreateAnAccount />
      </div>
      <h1 className={styles.title} id="info-circles">
        Create/edit profile
      </h1>
      <div>
        <InfoProfile />
      </div>
      <div id="use-the-app-edit">
        <CreateProfile />
      </div>
      <div id="use-the-app-challenge">
        <ChallengeAndForum />
      </div>
      <div id="use-the-app-profile">
        <ProfileAndMessages />
      </div>
      <h1 className={styles.title} id="info-circles">
        Create Circle
      </h1>
      <InfoCircle />
      <div id="use-the-app-create-circle">
        <CreateCircle />
      </div>
      <div id="use-the-app-create-challenge">
        <CreateChallnge />
      </div>
      <div id="use-the-app-sign-out">
        <SignOut />
      </div>
      <div id="use-the-app-log-in">
        <LogIn />
      </div>
      <div id="use-the-app-password">
        <ForgotPssword />
      </div>
      <div id="use-the-app-common-issue">
        <CommonIssue />
      </div>
    </div>
  );
};

import { FC } from 'react';
import { CATEGORIES } from 'src/const/Categories/Categories';
import { CategoryItem } from './CategoryItem/CategoryItem';
import styles from './Category.module.scss';

interface CategoryProps {
  selectedCategory: string | string[];
  onCategoryChange: (category: string) => void;
}

export const Category: FC<CategoryProps> = ({
  selectedCategory,
  onCategoryChange,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {CATEGORIES.map((item, index) => (
          <CategoryItem
            key={index}
            item={item}
            isSelected={selectedCategory.includes(item.name)}
            onClick={() => onCategoryChange(item.name)}
          />
        ))}
      </div>
    </div>
  );
};

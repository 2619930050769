import styles from './Home.module.scss';
import { UserInfo } from './UserInfo/UserInfo';
import { SubscriptionInfo } from './SubscriptionInfo/SubscriptionInfo';
// import TabsAuction from './Tabs/Tabs';
import { useEffect, useState } from 'react';
import { usersApi } from 'src/api/user';
import { UserInfoProps } from './const';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';
import { useNavigate } from 'react-router-dom';
import { PathRoutes } from 'src/const/Routes/PathRoutes';

export const Home = () => {
  const [userData, setUserData] = useState<UserInfoProps | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const res = await usersApi.userMe();
        const data = res.data;
        console.log(data, 'data');
        if (!data.result.full_name) {
          navigate(PathRoutes.CreateProfileRoute);
        } else {
          setUserData(data);
        }
      } catch (err) {
        console.log('Failed to load user data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [navigate]);

  return (
    <div className={styles.container}>
      {loading ? (
        <MaterialCircularPogress />
      ) : userData ? (
        <>
          <UserInfo {...userData} />
          <SubscriptionInfo {...userData} />
          {/* <TabsAuction /> */}
        </>
      ) : null}
    </div>
  );
};

import { useEffect } from 'react';
import { SectionComponent } from './SectionCompnent/SectionCompnent';
import { TOC, WHAT_IS_DUNBAR } from './const';
import { Terminolagy } from './Terminolagy/Terminolagy';
import { Subscription } from './Subscription/Subscription';
import Accordion from 'src/components/common/Accordion/Accordion';
import { Circle } from './Circle/Circle';
import styles from './HowItWorks.module.scss';
import { UseTheApp } from './UseTheApp/UseTheApp';
import { FAQS } from 'src/components/common/Accordion/const';

export const HowItWorks = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div className={styles.container}>
      <Accordion title="Table of Contents" items={TOC} />
      <div id="faq" className={styles.faq}>
        <Accordion title="FAQ" items={FAQS} />
      </div>
      <SectionComponent
        title={WHAT_IS_DUNBAR.title}
        description={WHAT_IS_DUNBAR.description}
        isSmallTitle
      />
      <h1 className={styles.title} id="terminology">
        Terminology
      </h1>
      <Terminolagy />
      <Subscription />
      <Circle />
      <UseTheApp />
      <h1 className={styles.title} id="supprot">
        Contact Support
      </h1>
      <div className={styles.contactUs}>
        Please{' '}
        <a
          href="https://dunbar-app.com/contact/"
          target="_blank"
          rel="noreferrer"
        >
          contact us.
        </a>
      </div>
    </div>
  );
};

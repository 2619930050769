import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

// Stage:
// https://lkipsnzyc6.execute-api.us-west-1.amazonaws.com/prod/api/
// no auth
// https://uvlvcdkpai.execute-api.us-west-1.amazonaws.com/prod/api/

// Prod:
// normal
// https://shb2h6g5rg.execute-api.us-east-2.amazonaws.com/prod/api/
// no auth
// https://m89okjsf18.execute-api.us-east-2.amazonaws.com/prod/api/

// const api = axios.create({
//   baseURL: 'https://shb2h6g5rg.execute-api.us-east-2.amazonaws.com/prod/api/',
// });

// export const apiNoAuth = axios.create({
//   baseURL: 'https://m89okjsf18.execute-api.us-east-2.amazonaws.com/prod/api/',
// });

const api = axios.create({
  baseURL: 'https://lkipsnzyc6.execute-api.us-west-1.amazonaws.com/prod/api/',
});

export const apiNoAuth = axios.create({
  baseURL: 'https://uvlvcdkpai.execute-api.us-west-1.amazonaws.com/prod/api/',
});

api.interceptors.request.use(
  async (config) => {
    try {
      const session = await fetchAuthSession();
      const accessToken = session?.tokens?.idToken?.toString();
      config.headers.Authorization = `Bearer ${accessToken}`;
    } catch (error) {
      console.log('Error fetching token:', error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;

import { FC, useEffect, useState } from 'react';
import { MuiModal } from 'src/components/material/MuiModal';
import ReactPlayer from 'react-player';
import styles from './SelectedVideoModal.module.scss';
import { postsApi } from 'src/api/postst';
import { CommentsSide } from './CommentsSide/CommentsSide';
import { CommentsData } from 'src/pages/UserCircle/conts';

interface SelectedVideoModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (open: boolean) => void;
  videoId: string;
  videoUrl: string;
}

export const SelectedVideoModal: FC<SelectedVideoModalProps> = ({
  isOpenModal,
  setIsOpenModal,
  videoId,
  videoUrl,
}) => {
  const [comments, setComments] = useState<CommentsData>({
    currentPage: 1,
    result: [],
    totalCommentCount: 0,
    totalPages: 1,
    children: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isOpenModal && videoId) {
      postsApi
        .getCommentsForSelectedPost(videoId)
        .then((res: any) => {
          setComments(res.data);
          setLoading(false);
        })
        .catch((error: any) => {
          console.error('Failed to fetch comments:', error);
        });
    }
  }, [isOpenModal, videoId]);

  return (
    <MuiModal
      open={isOpenModal}
      setOpen={setIsOpenModal}
      width={500}
      isVideoModal
    >
      <div className={styles.videoModalContent}>
        <div className={styles.video}>
          <ReactPlayer
            url={videoUrl}
            controls
            playing={isOpenModal}
            width="100%"
            height="700px"
            style={{ background: 'black' }}
          />
        </div>
        <CommentsSide
          commentsData={comments}
          videoId={videoId}
          loading={loading}
        />
      </div>
    </MuiModal>
  );
};

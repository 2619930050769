import React from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ReplyIcon from '@mui/icons-material/Reply';
import styles from '../CommentItem.module.scss';

interface CommentActionsProps {
  liked: boolean;
  likeCount: number;
  toggleLike: () => void;
  handleReplyClick: () => void;
  timestamp: string;
}

export const CommentActions: React.FC<CommentActionsProps> = ({
  liked,
  likeCount,
  toggleLike,
  handleReplyClick,
  timestamp,
}) => (
  <div className={styles.commentMeta}>
    <span className={styles.date}>{timestamp}</span>
    <div className={styles.actions}>
      <div className={styles.likeButton} onClick={toggleLike}>
        {liked ? (
          <FavoriteIcon style={{ color: 'red', cursor: 'pointer' }} />
        ) : (
          <FavoriteBorderIcon style={{ color: 'black', cursor: 'pointer' }} />
        )}
        <span className={styles.likeCount}>{likeCount}</span>
      </div>
      <div className={styles.replyButton} onClick={handleReplyClick}>
        <ReplyIcon style={{ color: 'black' }} />
      </div>
    </div>
  </div>
);

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthUser } from 'aws-amplify/auth';
import { PathRoutes } from 'src/const/Routes/PathRoutes';

interface ProtectedRouteProps {
  user: AuthUser | null;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ user }) => {
  return user ? <Outlet /> : <Navigate to={PathRoutes.HowItWorks} />;
};

export default ProtectedRoute;

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FC, ReactNode } from 'react';

interface MuiModalProps {
  children: ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  width?: number;
  isVideoModal?: boolean;
}

export const MuiModal: FC<MuiModalProps> = ({
  children,
  open,
  setOpen,
  width,
  isVideoModal,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    width: isVideoModal ? '100%' : 400,
    height: isVideoModal ? '100%' : 'auto',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box>
            <Box
              sx={{
                position: 'absolute',
                top: '9px',
                right: '6px',
                cursor: 'pointer',
              }}
            >
              <CloseOutlinedIcon
                onClick={handleClose}
                sx={{ color: 'black' }}
              />
            </Box>
            <Box>{children}</Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

import { FC, ReactNode } from 'react';
import styles from './ListItem.module.scss';

interface ListItemProps {
  title: string;
  description?: string[] | ReactNode[];
  img: string;
}

export const ListItem: FC<ListItemProps> = ({ img, title, description }) => {
  return (
    <div>
      <div className={styles.wrapper}>
        <img src={img} alt="" />
        <div className={styles.text}>
          <div className={styles.description}>{title}</div>
          <ul>
            {description?.map((item, index) => (
              <li key={index} className={styles.description}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

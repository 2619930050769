import api from './baseApi';

export const circleApi = {
  createCircle: (circleNewData: any) => {
    return api.post(`tribes/`, circleNewData);
  },

  editCircle: (circleNewData: any, id: string) => {
    return api.put(`tribes/${id}`, circleNewData);
  },

  getCircle: (id: string) => {
    return api.get(`tribes/${id}`);
  },

  getVideoId: (id: string) => {
    return api.get(`files/${id}`);
  },

  inviteMember: (tribe_id: string, role: string, phone_number: string) => {
    return api.post(`/tribes/${tribe_id}/invite`, {
      role,
      phone_number,
    });
  },

  deleteMember: (tribe_id: string, user_id: string) => {
    return api.put(`/tribes/${tribe_id}/leave`, {
      user_id,
    });
  },

  getMembersOfCircle: (id: string) => {
    return api.get(`/tribes/${id}/members`);
  },
};

import { FC } from 'react';
import styles from './SubscriptionInfo.module.scss';
import { AuctionProps } from '../conts';
import { CATEGORIES } from 'src/const/Categories/Categories';
import { CategoryItem } from 'src/components/common/Category/CategoryItem/CategoryItem';

export const SubscriptionInfo: FC<AuctionProps> = ({ price, categories }) => {
  const uniqueCategories = Array.from(new Set(categories));

  const getCategoryImage = (category: string) => {
    const categoryData = CATEGORIES.find((cat: any) => cat.name === category);
    return categoryData
      ? categoryData.img
      : CATEGORIES.find((cat: any) => cat.name === 'other')?.img;
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.info}>
          3 days, 17 hours <span>left</span>
        </div>
        <div className={styles.info}>
          {price}$<span>/month</span>
        </div>
        <div className={styles.info}>
          148 <span>spots</span>
        </div>
      </div>
      <div className={styles.categories}>
        <div className={styles.categoryList}>
          {uniqueCategories.map((category, index) => (
            <CategoryItem
              key={index}
              item={{ name: category, img: getCategoryImage(category) }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

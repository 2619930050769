/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react';

import styles from './CreateProfile.module.scss';
import { Button, Typography } from '@mui/material';
import { usersApi } from 'src/api/user';
import { UploadImage } from 'src/components/common/UploadIamge/UploadImage';
import { Header } from './Header/Header';
import { CommonFiled } from './CommonField/CommonField';
import Birthday from './Birthday/Birthday';
import { BIO_FIELD, EMAIL_FIELD, NAME_FIELD } from './const';
import { useNavigate } from 'react-router-dom';
import { PathRoutes } from 'src/const/Routes/PathRoutes';

interface ProfileFormData {
  phone_number: string;
  cognito_id: string;
  full_name: string;
  avatar_url: string;
  bio: string;
  birthday: string;
  email: string;
}

export const CreateProfile = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<ProfileFormData>({
    phone_number: '',
    cognito_id: '',
    full_name: '',
    avatar_url: '',
    bio: '',
    birthday: '',
    email: '',
  });
  const [error, setError] = useState<string>('');

  const handleImageUpdate = (fileId: string | File) => {
    if (typeof fileId === 'string') {
      setFormData({ ...formData, avatar_url: fileId });
    } else {
      console.log('Received a File object:', fileId);
    }
  };

  useEffect(() => {
    usersApi.userMe().then((res: any) => {
      const { phone_number, _id } = res.data.result;
      setFormData({ ...formData, phone_number: phone_number, cognito_id: _id });
    });
  }, []);

  const validateForm = (): boolean => {
    if (
      !formData.phone_number ||
      // !formData.cognito_id ||
      !formData.full_name ||
      !formData.avatar_url ||
      !formData.bio ||
      !formData.birthday ||
      !formData.email
    ) {
      setError('All fields are required');
      return false;
    }
    setError('');
    return true;
  };

  const createProfile = () => {
    if (!validateForm()) {
      return;
    }

    const profileNewData = {
      phone_number: formData.phone_number,
      // cognito_id: formData.cognito_id,
      full_name: formData.full_name,
      avatar_url: formData.avatar_url,
      bio: formData.bio,
      birthday: formData.birthday,
      email: formData.email,
    };

    usersApi
      .createProfile(profileNewData, formData.cognito_id)
      .then(() => {
        console.log('Profile created successfully');
        navigate(PathRoutes.HomeRoute);
      })
      .catch((error) => {
        setError('An error occurred while creating the profile.');
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Header />
        <CommonFiled
          title={NAME_FIELD.title}
          description={NAME_FIELD.description}
          placeholder={NAME_FIELD.placeholder}
          charactersLength={NAME_FIELD.charactersLength}
          isIcon
          anhor="info-name"
          value={formData.full_name}
          onChange={(e) =>
            setFormData({ ...formData, full_name: e.target.value })
          }
        />
        <UploadImage
          onUpdate={handleImageUpdate}
          defaultImage={
            typeof formData.avatar_url === 'string' ? formData.avatar_url : ''
          }
        >
          Add Photo
        </UploadImage>
        <CommonFiled
          type="textarea"
          title={BIO_FIELD.title}
          description={BIO_FIELD.description}
          placeholder={BIO_FIELD.placeholder}
          charactersLength={BIO_FIELD.charactersLength}
          anhor="info-bio"
          value={formData.bio}
          onChange={(e) => setFormData({ ...formData, bio: e.target.value })}
        />
        <Birthday
          value={formData.birthday}
          setValue={(value) => setFormData({ ...formData, birthday: value })}
        />
        <CommonFiled
          title={EMAIL_FIELD.title}
          description={EMAIL_FIELD.description}
          placeholder={EMAIL_FIELD.placeholder}
          charactersLength={EMAIL_FIELD.charactersLength}
          isIcon
          anhor="info-email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
        {error && (
          <Typography
            color="error"
            variant="body2"
            sx={{ mb: 1, mt: 1, textAlign: 'center' }}
          >
            {error}
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, textTransform: 'none', fontSize: '18px' }}
          size="large"
          onClick={createProfile}
        >
          Create Profile
        </Button>
      </div>
    </div>
  );
};

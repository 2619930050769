import { MuiModal } from 'src/components/material/MuiModal';
import { Button } from '@mui/material';
import { FC } from 'react';
import styles from './ProceedModal.module.scss';
import { circleApi } from 'src/api/circles';
import { FormData } from '../CreateCircle';

interface ProceedModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (open: boolean) => void;
  seats: number;
  formData: FormData;
  seatsAnother: number;
}

export const ProceedModal: FC<ProceedModalProps> = ({
  isOpenModal,
  setIsOpenModal,
  seats,
  formData,
  seatsAnother,
}) => {
  const createCircle = () => {
    circleApi.createCircle(formData).then((res) => {
      window.location.href = res.data.session_url;
    });
  };

  const remainingSeats = seats - seatsAnother;

  return (
    <MuiModal open={isOpenModal} setOpen={setIsOpenModal} width={300}>
      <div className={styles.container}>
        <div className={styles.description}>
          You coupon code have only {remainingSeats} seats remaining, Additional
          seats this will be billed at $10 each. Do you want to proceed?
        </div>
        <div className={styles.buttons}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ background: '#5b5b66' }}
            onClick={() => setIsOpenModal(false)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={createCircle}
          >
            Procced
          </Button>
        </div>
      </div>
    </MuiModal>
  );
};

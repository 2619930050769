import styles from './DonwloadTheApp.module.scss';
import { GooglePlayButton, AppStoreButton } from 'react-mobile-app-button';
import ios from 'src/assets/images/howToPage/Picture1.png';
import adnroid from 'src/assets/images/howToPage/Picture2.png';

const APKUrl =
  'https://play.google.com/store/apps/details?id=com.dunbarapp.Dunbar&hl=en&gl=US';
const IOSUrl = 'https://apps.apple.com/us/app/dunbar-app/id6469200374';

export const DonwloadTheApp = () => {
  return (
    <div className={styles.container}>
      <div className={styles.mainTitle}>Use the app</div>
      <div className={styles.title}>Download the app</div>
      <div className={styles.blockTitle}>1- Download the app</div>
      <div className={styles.description}>
        The Dunbar app is available on both the Apple AppStore and Google
        PlayStore.{' '}
        <strong>Click the appropriate icon below for the download link,</strong>{' '}
        or scan the associated QR code:
      </div>
      <div className={styles.content}>
        <div className={styles.item}>
          <AppStoreButton
            url={IOSUrl}
            theme={'dark'}
            className={styles.linkButton}
          />
          <div>
            <img src={ios} width={200} height={200} alt="" />
          </div>
        </div>
        <div className={styles.item}>
          <GooglePlayButton
            url={APKUrl}
            theme={'dark'}
            className={styles.linkButton}
          />
          <div>
            <img src={adnroid} width={200} height={200} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.description}>
        Links to the AppStore and PlayStore can also be found on our website:
        <a
          href="https://dunbar-app.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          dunbar-app.com.
        </a>
      </div>
    </div>
  );
};

import { useState, useEffect } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { GooglePlayButton, AppStoreButton } from 'react-mobile-app-button';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import { themeColors } from 'src/theme';
import styles from './Sidebar.module.scss';
import { Button } from '@mui/material';
import { MuiModal } from 'src/components/material/MuiModal';
import { RegisterModal } from './RegisterModal/RegisterModal';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import { currentUser } from 'src/redux/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { APKUrl, IOSUrl, ROUTES, ROUTES_NO_AUTH } from './const';
import { Circles } from './Circles/Circles';
import {
  fetchLoggedUser,
  selectCurrentUser,
} from 'src/redux/slices/loggedUserSlice';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  position: 'relative',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const Sidebar = () => {
  const isWideScreen = useMediaQuery('(min-width:550px)');
  const [open, setOpen] = useState(isWideScreen);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const user = useAppSelector(currentUser);
  const navigate = useNavigate();
  const selectedUser = useAppSelector(selectCurrentUser);
  const logginedUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedUser) {
      dispatch(fetchLoggedUser());
    }
    setOpen(isWideScreen);
  }, [isWideScreen, dispatch, selectedUser]);

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const filteredRoutes: any =
    logginedUser?.role === 'ADMIN'
      ? ROUTES
      : ROUTES.filter((route) => route.title !== 'Admin Panel');

  const routes = user ? filteredRoutes : ROUTES_NO_AUTH;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'space-between',
            // gap: '5px',
            height: '100%',
            marginTop: '20px',
            ml: open ? '20px' : '5px',
            mr: open ? '20px' : '5px',
          }}
        >
          <List>
            {routes.map((elem: any) => (
              <ListItem
                key={elem.title}
                disablePadding
                sx={{ display: 'flex' }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    height: '100%',
                    paddingLeft: open ? '0px' : '6.5px',
                  }}
                  onClick={() => handleNavigation(elem.path)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? '12px' : 'auto',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingLeft: open ? '0px' : '8px',
                    }}
                  >
                    {elem.icon}
                  </ListItemIcon>
                  <Box
                    className={styles.text}
                    sx={{
                      display: open ? 'block' : 'none',
                      color: themeColors.secondary.text,
                      fontSize: '14px',
                      fontWeight: '600',
                    }}
                  >
                    {elem.title}
                  </Box>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          {!user && (
            <Box
              sx={{
                color: themeColors.text.dark70,
                display: open ? 'block' : 'none',
                mt: 2,
                mb: 2,
              }}
            >
              Log in to follow creators
            </Box>
          )}
          {user ? (
            <Box sx={{ color: themeColors.secondary.text }}>
              <Circles />
            </Box>
          ) : (
            <Box>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                className={styles['btn']}
                sx={{
                  display: open ? 'block' : 'none',
                }}
                onClick={() => setIsOpenModal(true)}
              >
                Log in
              </Button>
              <MuiModal open={isOpenModal} setOpen={setIsOpenModal}>
                <RegisterModal />
              </MuiModal>
            </Box>
          )}
          <div className={styles.buttons}>
            <GooglePlayButton
              url={APKUrl}
              theme={'dark'}
              className={styles.linkButton}
            />
            <AppStoreButton
              url={IOSUrl}
              theme={'dark'}
              className={styles.linkButton}
            />
          </div>
        </Box>
      </Drawer>
    </Box>
  );
};

import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import { UploadIcon } from 'src/assets/icons/upload-icon';
import styles from './UploadVideo.module.scss';
import { usersApi } from 'src/api/user';
import { circleApi } from 'src/api/circles';
import Hls from 'hls.js';

interface UploadVideoProps {
  defaultVideo: string;
  onUpdate: (fileId: string | File) => void;
  classNameVideoContainer?: string;
  children?: React.ReactNode;
  helperText?: string;
}

export const UploadVideo: FC<UploadVideoProps> = ({
  defaultVideo,
  onUpdate,
  classNameVideoContainer,
  children,
  helperText,
}) => {
  const [previewVideo, setPreviewVideo] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  // Set the default video on mount or when it changes
  useEffect(() => {
    if (defaultVideo) {
      setPreviewVideo(defaultVideo);
    }
  }, [defaultVideo]);

  // Update and reset the video element whenever previewVideo changes
  useEffect(() => {
    const videoElement = document.getElementById(
      'video-player'
    ) as HTMLVideoElement;

    if (videoElement) {
      videoElement.pause();
      videoElement.src = ''; // Reset src to clear the previous video
      videoElement.load(); // Reload the video element

      if (previewVideo && previewVideo.endsWith('.m3u8') && Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(previewVideo);
        hls.attachMedia(videoElement);
      } else if (previewVideo) {
        videoElement.src = previewVideo; // Set new src for non-HLS videos
      }
    }
  }, [previewVideo]);

  const handleVideoClick = () => {
    fileRef.current?.click();
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.[0];

    if (uploadedFile) {
      if (uploadedFile.size <= 104857600) {
        // Max size 100MB
        setIsUploading(true);
        try {
          // Step 1: Request a pre-signed URL from the backend
          const response = await usersApi.uploadFile({
            contentType: uploadedFile.type,
            name: uploadedFile.name,
            type: 'video-welcome',
          });

          const { uploadURL, Key } = response.data;

          // Step 2: Upload the video to S3 using the pre-signed URL
          const uploadResponse = await fetch(uploadURL, {
            method: 'PUT',
            headers: {
              'Content-Type': uploadedFile.type,
            },
            body: uploadedFile,
          });

          if (!uploadResponse.ok) {
            throw new Error(
              `Upload failed with status: ${uploadResponse.status}`
            );
          }

          // Step 3: Retrieve the video ID and generate preview
          const getVideoId = await circleApi.getVideoId(Key);
          const previewUrl = URL.createObjectURL(uploadedFile);

          // Revoke the old preview URL to free memory
          if (previewVideo) {
            URL.revokeObjectURL(previewVideo);
          }

          setPreviewVideo(previewUrl); // Set the new video preview
          onUpdate(getVideoId.data._id); // Notify the parent component
        } catch (error) {
          console.error('Error uploading file:', error);
          alert('Error uploading file. Check the console for more details.');
        } finally {
          setIsUploading(false);
        }
      } else {
        alert('Video size should not exceed 100MB');
      }
    }
  };

  const handleIconClick = () => {
    navigate(`/how-it-works#info-video`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.title}>Video</div>
        <div onClick={handleIconClick} className={styles.infoIcon}>
          <InfoOutlinedIcon style={{ color: 'red', cursor: 'pointer' }} />
        </div>
      </div>
      <div className={styles.description}>
        Similar to the Description, this is your chance to pitch the purpose and
        value of your Circle.
      </div>
      <div
        className={classNames(styles.videoContainer, classNameVideoContainer)}
        onClick={handleVideoClick}
      >
        {!previewVideo && !isUploading && (
          <div className={styles.videoWrapper}>
            <div className={styles.uploadText}>Drag and Drop video or</div>
            <div className={styles.icon}>
              <UploadIcon />
            </div>
          </div>
        )}
        {isUploading && <div className={styles.uploading}>Uploading...</div>}
        {previewVideo && !isUploading && (
          <video
            id="video-player"
            controls
            className={styles.previewVideo}
            style={{ width: '100%', height: '350px', display: 'block' }}
          >
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      <div className={styles.maxDurationText}>Maximum 60 seconds</div>

      <input
        accept=".mp4, .mov, .avi"
        type="file"
        className={styles.hiddenInput}
        ref={fileRef}
        onChange={handleInputChange}
      />
    </div>
  );
};

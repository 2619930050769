import React, { FC } from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@mui/material';
import MaterialTextField from 'src/components/material/textField';

import styles from '../SignUpType.module.scss';
import { secondStepSchema, SecondStepInput } from '../const';

interface SecondStepProps {
  onSubmit: SubmitHandler<SecondStepInput>;
  error: string;
}

export const SecondStep: FC<SecondStepProps> = ({ onSubmit, error }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SecondStepInput>({
    resolver: zodResolver(secondStepSchema),
  });

  const onSubmitHandler = (data: SecondStepInput) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className={styles.form}>
      <Controller
        name="confirmationCode"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <MaterialTextField
            label="Confirmation Code"
            variant="outlined"
            error={!!errors.confirmationCode}
            helperText={
              errors.confirmationCode ? errors.confirmationCode.message : error
            }
            {...field}
            color="primary"
          />
        )}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: '24px', mb: 2, textTransform: 'none', fontSize: '18px' }}
        size="large"
      >
        Confirm
      </Button>
    </form>
  );
};

import { FC } from 'react';
import { TribeDetail } from 'src/pages/Home/const';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import styles from './CircleItem.module.scss';
import { useMediaQuery } from '@mui/material';

export const Circleitem: FC<{ item: TribeDetail }> = ({ item }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/circle/${item._id}`);
  };

  const isWideScreen = useMediaQuery('(min-width:550px)');

  const getTruncatedTribeName = (name: string) => {
    if (name.length > 15) {
      return `${name.slice(0, 13)}...`;
    }

    return name;
  };

  const formatRole = (role: string) => {
    if (!role) return '';
    return role.charAt(0).toUpperCase() + role.slice(1).toLowerCase();
  };

  if (!item.tribe_name) {
    return null;
  }

  return (
    <div onClick={handleClick} className={styles.container}>
      <Avatar
        src={
          item.tribe_avatar_url ? item.tribe_avatar_url : item.leader_avatar_url
        }
        sx={{ width: isWideScreen ? 45 : 35, height: isWideScreen ? 45 : 35 }}
      >
        {item.tribe_name ? item.tribe_name.charAt(0) : 'C'}
      </Avatar>
      <div className={styles.textWrapper}>
        <div className={styles.tribeName}>
          {getTruncatedTribeName(item.tribe_name)}
        </div>
        <div className={styles.leaderName}>{formatRole(item.role)}</div>
      </div>
    </div>
  );
};

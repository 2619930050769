import React, { useState } from 'react';
import { type ResetPasswordOutput } from 'aws-amplify/auth';
import { useSendCode } from 'src/hooks/ForgetPassword/UseSendCode';
import { useConfirmResetPassword } from 'src/hooks/ForgetPassword/useConfirmResetPassword';
import { FirstStep } from './FirstStep/FirstStep';
import { SecondStep } from './SecondStep/SecondStep';
import { ThirdStep } from './ThirdStep/ThirdStep';
import { Copyright } from 'src/components/material/Copyright';
import { Box } from '@mui/material';
import phoneScreen from 'src/assets/images/screen/phone-screen.jpeg';
import styles from './ForgetPassword.module.scss';
import { Logo } from 'src/components/common/Logo/Logo';

export const ForgetPassword: React.FC = () => {
  const [step, setStep] = useState<number>(1);

  const {
    phoneNumber,
    setPhoneNumber,
    sendCode,
    error: sendCodeError,
    setError: setSendCodeError,
  } = useSendCode();

  const {
    code,
    setCode,
    newPassword,
    setNewPassword,
    confirmResetPassword,
    error: confirmResetPasswordError,
  } = useConfirmResetPassword();

  const handleSendCode = async () => {
    try {
      const output = await sendCode();
      handleResetPasswordNextSteps(output);
    } catch (err) {
      console.error(err);
    }
  };

  const handleResetPasswordNextSteps = (output: ResetPasswordOutput) => {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        setStep(2);
        break;
      case 'DONE':
        setStep(3);
        break;
      default:
        setSendCodeError('Unexpected step');
    }
  };

  const handleConfirmResetPassword = async () => {
    try {
      await confirmResetPassword(phoneNumber);
      setStep(3);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <Box
          sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo />
          {step !== 3 && (
            <Box
              sx={{
                mt: 0,
                mb: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#191920',
                fontSize: '24px',
                fontWeight: '600',
              }}
            >
              Reset password
            </Box>
          )}
          {step === 1 && (
            <FirstStep
              error={sendCodeError}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              handleSendCode={handleSendCode}
            />
          )}
          {step === 2 && (
            <SecondStep
              error={confirmResetPasswordError}
              code={code}
              setCode={setCode}
              newPassword={newPassword}
              setNewPassword={setNewPassword}
              handleConfirmResetPassword={handleConfirmResetPassword}
            />
          )}
          {step === 3 && <ThirdStep />}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4, color: '#191920' }} />
      </div>
      <div className={styles.rightSide}>
        <img
          src={phoneScreen}
          className={styles.screenLogo}
          alt="phone screen"
        />
      </div>
    </div>
  );
};

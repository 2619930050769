import { HashLink as Link } from 'react-router-hash-link';
import styles from '../../components/common/Accordion/AccordionItem/AccordionItem.module.scss';

const smoothScroll = (el: any) => {
  el.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

export const WHAT_IS_DUNBAR = {
  title: 'What is Dunbar',
  description: [
    'Dunbar is a personal development platform designed to help individuals grow, connect, and achieve their goals through small, consistent daily actions. Named after the renowned anthropologist Robin Dunbar, who discovered that humans can maintain meaningful relationships with about 148 people, Dunbar leverages this principle to create focused, supportive communities known as Circles.',
    'On Dunbar, users join Circles based on their interests or objectives, where they receive daily micro-learning challenges from leaders or mentors. These challenges are designed to be quick and manageable, fostering habits that lead to significant personal growth over time. By sharing experiences, progress, and support within their Circles, members build a sense of belonging and motivation, making their journey towards personal and professional development more engaging and effective.',
    'Dunbar offers a unique blend of social interaction and learning, using short-form videos and interactive features to keep users engaged and inspired. Whether you want to improve your fitness, enhance your leadership skills, or cultivate mindfulness, Dunbar provides the tools and community to help you succeed.',
  ],
};

export const CIRCLES = {
  title: 'Circles',
  description: [
    'Circles are the core communities within the Dunbar platform, where Members come together to pursue common goals and interests. Each Circle is led by a mentor or leader who guides the group by sharing daily challenges, insights, and motivational content.',
    'A Circle is a small, focused group that never exceeds 148 individuals, reflecting the principle that people can maintain meaningful relationships within this limit. This ensures a close-knit and supportive environment where Members can genuinely connect and interact.',
    'Circles can be centered around various themes, such as creative writing, entrepreneurship, or wellness. In a Circle, Members engage by participating in daily micro-learning activities, posting their progress, sharing experiences, and offering encouragement to one another. The interactive environment fosters a sense of belonging and accountability, making it easier for everyone to stay committed to their goals.',
    'Joining a Circle means you are not alone in your journey. You have a community that celebrates your successes, helps you overcome challenges, and keeps you motivated. Whether you’re looking to develop new skills, form healthy habits, or simply connect with others who share your passion, Circles provide the perfect space to grow and thrive together.',
  ],
};

export const LEADERS = {
  title: 'Leaders',
  description: [
    'Leaders are the mentors and guides within Dunbar who create and manage Circles. They play a crucial role in inspiring and motivating Members by sharing their expertise, experiences, and insights. Leaders are responsible for setting the tone of the Circle and providing daily Challenges that encourage Members to take small, consistent actions towards their goals.',
    'A Leader could be an expert in various fields, such as an accomplished author for a creative writing Circle, a seasoned entrepreneur for a business startup Circle, or a wellness coach for a mental health Circle. Regardless of their field, Leaders bring valuable knowledge and experience that help Members grow and achieve their personal and professional aspirations.',
    'Leaders engage with Members through short, impactful videos and posts that deliver micro-learning content. They encourage interaction, foster discussions, and provide feedback to create a supportive and engaging environment. By sharing their own journeys and the small, daily practices that have led to their success, Leaders help Members stay motivated and focused.',
    'Being a Leader in Dunbar is not just about providing guidance; it`s about building a community where everyone feels valued and inspired. Leaders are the driving force behind the Circles, ensuring that each Member feels connected, supported, and empowered to reach their full potential.',
  ],
};

export const MEMBERS = {
  title: 'Members',
  description: [
    'Members are the heart and soul of Dunbar. They are individuals who join Circles to pursue personal and professional growth alongside like-minded peers. By participating in daily Challenges and engaging with the community, Members contribute to the vibrant, supportive environment that defines Dunbar.',
    'As a Member, you are part of a small, focused group of up to 148 individuals, ensuring meaningful interactions and a strong sense of community. Members come from diverse backgrounds and join Circles based on their specific interests and goals, whether it’s enhancing their skills, building new habits, or simply connecting with others who share their passions.',
    'In a Circle, Members participate in daily micro-learning activities designed by Leaders. They post their progress, share experiences, and offer encouragement to one another. This collaborative and interactive approach helps Members stay motivated and accountable, making it easier to achieve their goals.',
    'Being a Member of Dunbar means you are not alone on your journey. You have access to valuable insights, support, and inspiration from both Leaders and fellow Members. By actively engaging in your Circle, you contribute to a culture of continuous learning and mutual growth, making your personal development journey more enjoyable and effective.',
  ],
};

export const CHALLENGE = {
  title: 'Challenges',
  description: [
    'Challenges are the daily activities or tasks set by Leaders within Dunbar Circles to help Members achieve their personal and professional goals. These Challenges are designed to be simple, manageable, and impactful, encouraging Members to take small, consistent steps that lead to significant growth over time.',
    'Each Challenge is typically a short activity that can be completed in a few minutes, fitting seamlessly into the busy lives of Members. Whether it’s a quick exercise, a reflective journaling prompt, or a new skill to practice, Challenges are tailored to the specific focus of the Circle and the needs of its Members.',
    'Challenges serve several key purposes:',
    <ul>
      <li>
        <strong>Motivation:</strong> They provide a daily dose of inspiration
        and a clear action to take, keeping Members motivated and engaged.
      </li>
      <li>
        <strong>Accountability:</strong> By participating in Challenges, Members
        hold themselves accountable and can track their progress over time.
      </li>
      <li>
        <strong>Community Building:</strong> Sharing experiences and results
        from Challenges fosters a sense of camaraderie and mutual support among
        Members.
      </li>
    </ul>,
    'Completing Challenges and sharing your experiences with the Circle helps create a dynamic and supportive learning environment. It’s not just about individual achievement; it’s about growing together and celebrating each other’s successes.',
    'By regularly participating in Challenges, Members can build new habits, gain valuable insights, and make steady progress towards their goals. In Dunbar, every small action counts, and Challenges are the stepping stones that help you reach your full potential.',
  ],
};

export const SUB = {
  title: 'Join a Circle',
  description: [
    'Dunbar is designed to foster small, meaningful communities (up to 148 Members) around common goals. The platform emphasizes micro-learning through short, daily videos and active participation in community discussions. In order to keep the communities, referred to as Circles, so small, subscription prices differ depending on the popularity of the Circle.',
    'Subscriptions on Dunbar offer a unique and engaging way for Members to join exclusive Circles led by Leaders in various fields. Unlike traditional subscription models that offer static pricing, Dunbar introduces a dynamic and competitive approach to subscriptions, ensuring that the most dedicated and enthusiastic Members can secure their spots in the Circles they are most passionate about.',
    'This innovative model fosters a meaningful community, as each Circle is limited to a small, focused group of up to 148 individuals. This structure not only enhances the quality of interactions but also ensures that each Member receives personalized attention and support.',
  ],
};

export const AUCTION = {
  title: 'Auction',
  description: [
    'When a Circle is created, the Leader announces a 7-day registration period, a Grand Auction, where followers bid for limited spots. The highest bidders secure their places, creating a competitive but fair way to join. If more bids are placed at the same price level than there are available spots, priority is given to the earliest bids. Unsuccessful bidders are placed on a Waitlist for future openings. This system ensures that the most committed Members, who see the highest value in the Circle, are the ones who gain access.',
  ],
};

export const GRAND_AUCTION = {
  title: '• Grand Auction (7 days)',
  description: [
    'During the Grand Auction of a new Circle, all spots are made available through an initial auction. This event allows Members enough time to discover the opportunity and to bid for their place in the Circle. The Grand Auction sets the tone for the Circle, bringing together the most enthusiastic and motivated Members right from the start.',
    'Unlike typical auctions, Members do not set the price they’re willing to pay themselves. The price is pre-set in fixed increments by Dunbar and works the same for every Circle. The current price to join a Circle is what you see listed when you join a Circle.',
    'You only pay the amount on your screen when you join a Circle if you gain access to the Circle after the 7-day period and you are not outbid. If you are outbid, you do not pay anything.',
    'If there are more bids at the same price than there are spots available in the Circle, priority is given to the earliest bids - so there is a benefit to joining early. Potential Members who are outbid are placed on the Waitlist and given the chance to try again as many times as they like within the 7-day period.',
  ],
};

export const MINI_AUCTION = {
  title: '• Mini Auction (24 hours)',
  description: [
    'Occasionally, additional spots in a Circle are made available outside of the Grand Auction. These can happen periodically or in response to increased demand. As Members leave, new spots open up, allowing new, potential Members to join through a Mini Auction. This process ensures that the group remains dynamic and committed.',
    'When a Mini Auction is launched for one or more new available spots, everyone on the Waitlist for the Circle is notified. This auction works the same way as the Grand Auction, but lasts 24 hours instead of seven days.',
  ],
};

export const WAITLIST = {
  title: 'Waitlist',
  description: [
    'The Waitlist is a queue for individuals who bid for a spot but were not initially successful. When a Circle is full, Members who are still interested in joining can add themselves to a Waitlist. The Waitlist ensures that as soon as a spot becomes available, Members on the Waitlist are notified and given 24 hours to join. ',
    'It is free to be on the Waitlist. ',
  ],
};

export const CONSTANT_SUB_FEE = {
  title: 'Subscription Fee',
  description: [
    'Once you are granted access to a Circle, your subscription fee never changes from what you initially committed to, even if the price increases for new Members that join after you.',
  ],
};

export const END_SUB = {
  title: 'Ending Your Subscription',
  description: [
    'Subscriptions go month-to-month. If Members wish to end their subscription, they can do so at any time without any additional charges. They will retain access to the Circle until the end of their current monthly billing period.',
  ],
};

export const TLDR = {
  title: 'TL;DR',
  description: [
    <>
      <ul>
        <li>
          You never pay more per month than what you commit to on the payment
          screen.
        </li>
        <li>You may cancel at any time.</li>
        <li>
          You are not charged anything if you are placed on, or join, the
          Waitlist.
        </li>
      </ul>
    </>,
  ],
};

export const CREATING_CIRCLE = {
  title: 'Create a Circle',
  description: [
    'Creating a Circle on Dunbar is an empowering opportunity to lead and cultivate a community around a shared purpose or interest. As a Leader, you have the chance to guide Members, foster genuine connections, and drive meaningful personal and professional growth within a focused, supportive environment.',
  ],
};

export const PURPOSE_OF_CREATING_CIRCLE = {
  title: 'Purpose of Creating a Circle',
  description: [
    'The primary purpose of creating a Circle is to bring together individuals who share common goals, interests, or values. Whether your focus is on fitness, leadership, mindfulness, or anything you want, your Circle will serve as a dedicated space for Members to learn, grow, and support each other. By creating a Circle, you set the stage for ongoing engagement, motivation, and collaboration among like-minded individuals.',
  ],
};

export const WHO_SHOUD_CREATE_CIRCLE = {
  title: 'Who Should Create a Circle?',
  description: [
    'Anyone with a passion for a specific topic and a desire to lead and support others can create a Circle. You might be:',
    <>
      <ul>
        <li>A professional looking to mentor others in your field.</li>
        <li>An enthusiast wanting to share your hobby with a community.</li>
        <li>A company leader aiming to enhance team cohesion and growth.</li>
        <li>
          An individual seeking to create an additional income stream through a
          subscription-based Circle.
        </li>
      </ul>
    </>,
  ],
};

export const WHAT_DOES_IT_ENTAIL = {
  title: 'What Does It Entail?',
  description: [
    'Creating and managing a Circle involves several key steps and ongoing responsibilities:',
    <>
      <ol>
        <li>
          <strong>Name Your Circle:</strong> Choose a name that reflects the
          purpose or focus of your Circle. This can be your own name or a
          descriptive name that clearly conveys the Circle’s mission.
        </li>
        <li>
          <strong>Select Categories:</strong> Identify up to four categories
          that best describe your Circle. These categories help potential
          Members understand the focus and intent of your Circle.
        </li>
        <li>
          <strong>Write a Description:</strong> Clearly articulate who you are
          and the core values and aspirations of your Circle. This description
          will attract Members who resonate with your vision and goals.
        </li>
        <li>
          <strong>Create a Video:</strong> Record a brief video pitch to
          introduce yourself and explain the purpose and value of your Circle.
          This personal touch helps potential Members connect with you and
          understand what to expect.
        </li>
        <li>
          <strong>Set the Number of Members:</strong> Decide the initial maximum
          number of Members for your Circle. You can adjust this number later as
          your community grows.
        </li>
        <li>
          <strong>Define Habits:</strong> List up to five daily activities or
          general mindsets that lead to success for your Circle’s purpose. As a
          Leader, you will support Members in maintaining these habits, setting
          clear expectations for those considering joining.
        </li>
        <li>
          <strong>Choose Subscription Model:</strong> Decide whether your Circle
          will be free or subscription-based. For individual Leaders seeking to
          generate income, enabling Paid Subscriptions will allow Dunbar to set
          the price through a week-long auction once the Circle goes live.
        </li>
      </ol>
    </>,
  ],
};

export const ONGOING_RESPONS = {
  title: 'Ongoing Responsibilities',
  description: [
    'As a Leader, you will continuously engage with your Circle through regular updates, challenges, and interactions. Your role includes:',
    <>
      <ul>
        <li>
          <strong>Posting Regular Content:</strong> Share micro-lessons, daily
          activities, or insights that align with your Circle’s focus.
        </li>
        <li>
          <strong>Engaging with Members:</strong> Foster discussions, answer
          questions, and provide support within your Circle’s forum.
        </li>
        <li>
          <strong>Maintaining Habits:</strong> Encourage Members to adopt and
          maintain the daily habits you’ve defined, helping them achieve their
          goals.
        </li>
        <li>
          <strong>Growing Your Circle:</strong> Invite new Members and adjust
          the Circle’s size as needed to ensure a dynamic and supportive
          community.
        </li>
      </ul>
    </>,
    'By creating a Circle on Dunbar, you embark on a rewarding journey of leadership, community-building, and shared growth. Whether for personal fulfillment, professional development, or both, leading a Circle allows you to make a meaningful impact on the lives of your Members.',
  ],
};

export const NAME = {
  title: 'Name',
  description: [
    'It`s best to use your real name to foster genuine interactions and build trust within your Circle. Dunbar Circles are small, private groups designed to create a strong sense of belonging and meaningful connections. Your real name helps others recognize and connect with you more authentically. Rest assured, only your Leader and the other Members in your closed Circle will see your name, and all communications within your Circle are 100% private and secure.',
  ],
};

export const PHOTO = {
  title: 'Photo',
  description: [
    'Using a real photo of yourself is encouraged. Dunbar is all about authenticity, and a real photo helps to foster trust and genuine connections within your Circle. Your photo will only be visible to your Leader and the other Members in your closed Circle, ensuring a secure and private environment for meaningful interactions.',
  ],
};
export const BIO = {
  title: 'Bio',
  description: [
    'When writing your bio for Dunbar, we value genuine connections over polished facades. To enrich your experience, we encourage you to be open and generous in your profile. Embrace vulnerability, celebrate your uniqueness, and let your story shine. Your bio is a great place to share your interests, experiences, and what drives you, helping to foster deeper and more meaningful connections within your Circle. Rest assured, only your Leader and the other Members in your closed Circle will be able to see your bio.',
  ],
};
export const BIRTHDAY = {
  title: 'Birthday',
  description: [
    'We ask for this to help ensure that underage people aren`t using Dunbar. Your birth year is confidential and will not be shared with anyone, including your Leader and other Members. This information helps us maintain a safe and appropriate environment for all Members.',
  ],
};
export const EMAIL = {
  title: 'Email',
  description: [
    'When adding your email address in Dunbar, it helps keep your account secure and provides another option to reset your password if needed. Your email will not be shared with anyone, ensuring your privacy is maintained while enhancing your accoun`s security.',
  ],
};
export const CREATE_CIRCLE = {
  title: 'Create Circle',
  description: [
    <>
      If you want to become the Leader of your very own Circle, this is where
      you do it. To learn more about what that entails, read{' '}
      <Link to={`/how-it-works#info-circle-creation`} scroll={smoothScroll}>
        Creating a Circle on Dunbar.
      </Link>
    </>,
  ],
};

export const CIRCLE_NAME = {
  title: 'Circle name',
  description: [
    'Use your own name or choose a name that describes the purpose of your Circle. This helps set the tone and focus for your Circle, making it easier for Members to understand its intent and connect with its mission. Choose a name that reflects your goals and the community you aim to create.',
  ],
};
export const CATEGORIES = {
  title: 'Categories',
  description: [
    'Choose up to four categories that best represent your Circle`s focus. Categories range from fitness, health, and relationships to leadership, environment, and many more. Selecting the right categories helps potential Members understand the intent of your Circle and ensures a cohesive and meaningful experience for everyone involved.',
  ],
};
export const DESCRIPTION = {
  title: 'Description',
  description: [
    'Use this space to convey who you are and articulate the core values and aspirations of your Circle. Share your vision, goals, and what you hope to achieve with your Members. This is your opportunity to attract like-minded individuals who resonate with your mission and values. Be clear, inspiring, and authentic to set the tone for a supportive and engaging community.',
  ],
};
export const VIDEO = {
  title: 'Video',
  description: [
    'This is your chance to pitch the purpose and value of your Circle. Introduce yourself, share your vision, and explain what makes your Circle unique. Highlight the core values, goals, and benefits that Members will gain by joining. Keep it concise, engaging, and authentic to inspire potential Members and give them a clear sense of what to expect. This video will help set the tone for your Circle and attract individuals who resonate with your mission. ',
    '',
  ],
};
export const MEMBERS_INFO = {
  title: 'Members',
  description: [
    'When setting the number of Members for your Circle, choose the maximum number of Members you wish to admit initially. Remember, you can always admit more Members later as your Circle grows. Consider starting with a manageable size to foster close connections and meaningful interactions. This allows you to build a strong foundation before expanding your community.',
  ],
};
export const HABITS = {
  title: 'Habits',
  description: [
    'Describe the daily activities and general mindset that lead to success for your Circle`s purpose. List up to five habits that Members can expect to incorporate into their daily routines after they join your Circle. As a Leader, you will provide guidance, support, and encouragement to help Members maintain these activities. Defining these habits helps set expectations for Members considering joining, ensuring everyone is aligned with the Circle’s mission. As with most aspects of your Circle, you can change or add to these at any time.',
  ],
};
export const PAID_OR_FREE = {
  title: 'Paid Subscriptions or Free',
  description: [
    <>
      You have the option to choose between Paid Subscriptions or Free
      membership. If you are acting on behalf of a company using Dunbar for
      corporate training, you will not enable Paid Subscriptions. However, if
      you are an individual starting a Circle as a new income stream, you can
      turn on Paid Subscriptions. If enabled, Dunbar will automatically set the
      price through a week-long auction once the Circle goes live. For more
      details on how auctions work, please refer to{' '}
      <Link to={`/how-it-works#info-sub`} scroll={smoothScroll}>
        Subscriptions.
      </Link>
    </>,
  ],
};

export const VIDEO_POST = {
  title: 'Video',
  description: [
    'This video is your call to action, where you describe the daily activity you want your Members to attempt. Clearly explain the Challenge, its purpose, and how it aligns with your Circle’s goals. Your video should be engaging and motivational, inspiring Members to participate and stay committed to the challenge. Keep it concise and focused, ensuring that your message is clear and easy to follow.',
  ],
};

export const HABITS_POST = {
  title: 'Challenges',
  description: [
    'These written challenges should reflect what you covered in the video and provide detailed instructions for your Members. You can define up to five challenges, each describing specific activities you want your Members to attempt. Make sure your descriptions are clear and actionable, helping Members understand exactly what they need to do. By providing both a video and written challenges, you create a comprehensive guide that encourages active participation and helps Members stay on track with their personal and collective goals.',
  ],
};

export const TOC = [
  {
    question: (
      <>
        {' '}
        <Link to={`/how-it-works#faq`} scroll={smoothScroll}>
          FAQ
        </Link>
      </>
    ),
  },
  {
    question: 'What is Dunbar',
    answer: (
      <>
        <div className={styles.anserLink}>
          {' '}
          <Link to={`/how-it-works#terminology`} scroll={smoothScroll}>
            Terminology
          </Link>
        </div>
        <ul>
          <li>
            <Link to={`/how-it-works#terminology-circle`} scroll={smoothScroll}>
              Circles
            </Link>
          </li>
          <li>
            <Link
              to={`/how-it-works#terminology-leaders`}
              scroll={smoothScroll}
            >
              Leaders
            </Link>
          </li>
          <li>
            <Link
              to={`/how-it-works#terminology-members`}
              scroll={smoothScroll}
            >
              Members
            </Link>
          </li>
          <li>
            <Link
              to={`/how-it-works#terminology-challenges`}
              scroll={smoothScroll}
            >
              Challenges
            </Link>
          </li>
        </ul>
      </>
    ),
  },
  {
    question: 'Join a Circle',
    answer: (
      <>
        <div className={styles.anserLink}>
          <Link
            to={`/how-it-works#join-a-circle-auction`}
            scroll={smoothScroll}
          >
            Auction
          </Link>
        </div>
        <ul>
          <li>
            <Link
              to={`/how-it-works#join-a-circle-grand-auction`}
              scroll={smoothScroll}
            >
              Grand Auction (7 days)
            </Link>
          </li>
          <li>
            <Link
              to={`/how-it-works#join-a-circle-mini-auction`}
              scroll={smoothScroll}
            >
              Mini Auction (24 hours)
            </Link>
          </li>
          <li>
            <Link
              to={`/how-it-works#join-a-circle-waitlist`}
              scroll={smoothScroll}
            >
              Waitlist
            </Link>
          </li>
        </ul>
        <div className={styles.anserLink}>
          <Link
            to={`/how-it-works#join-a-circle-subscription-fee`}
            scroll={smoothScroll}
          >
            Subscription Fee
          </Link>
        </div>
        <div className={styles.anserLink}>
          <Link
            to={`/how-it-works#join-a-circle-ending-sub`}
            scroll={smoothScroll}
          >
            Ending Your Subscription
          </Link>
        </div>
        <div className={styles.anserLink}>
          <Link to={`/how-it-works#join-a-circle-tldr`} scroll={smoothScroll}>
            TL;DR
          </Link>
        </div>
      </>
    ),
  },
  {
    question: 'Create a Circle',
    answer: (
      <>
        <div className={styles.anserLink}>
          <Link to={`/how-it-works#info-circle-purpose`} scroll={smoothScroll}>
            Purpose of Creating a Circle
          </Link>
        </div>
        <div className={styles.anserLink}>
          <Link
            to={`/how-it-works#info-circle-who-shoud`}
            scroll={smoothScroll}
          >
            Who Should Create a Circle?
          </Link>
        </div>
        <div className={styles.anserLink}>
          <Link to={`/how-it-works#info-circle-entail`} scroll={smoothScroll}>
            What Does It Entail?
          </Link>
        </div>
        <div className={styles.anserLink}>
          <Link
            to={`/how-it-works#info-circle-responsibilities`}
            scroll={smoothScroll}
          >
            Ongoing Responsibilities
          </Link>
        </div>
      </>
    ),
  },
  {
    question: 'Use the App',
    answer: (
      <>
        <div className={styles.anserLink}>
          <Link to={`/how-it-works#use-the-app-donwload`} scroll={smoothScroll}>
            Download the app
          </Link>
        </div>
        <div className={styles.anserLink}>
          <Link to={`/how-it-works#use-the-app-create`} scroll={smoothScroll}>
            Create an account
          </Link>
        </div>
        <div className={styles.anserLink}>
          <Link to={`/how-it-works#use-the-app-edit`} scroll={smoothScroll}>
            Create/edit profile
          </Link>
        </div>
        <ul>
          <li>
            <Link to={`/how-it-works#info-name`} scroll={smoothScroll}>
              Name
            </Link>
          </li>
          <li>
            <Link to={`/how-it-works#info-photo`} scroll={smoothScroll}>
              Photo
            </Link>
          </li>
          <li>
            <Link to={`/how-it-works#info-bio`} scroll={smoothScroll}>
              Bio
            </Link>
          </li>
          <li>
            <Link to={`/how-it-works#info-birthday`} scroll={smoothScroll}>
              Birthday
            </Link>
          </li>
          <li>
            <Link to={`/how-it-works#info-email`} scroll={smoothScroll}>
              Email
            </Link>
          </li>
        </ul>
        <div className={styles.anserLink}>
          <Link
            to={`/how-it-works#use-the-app-challenge`}
            scroll={smoothScroll}
          >
            Challenges and forum
          </Link>{' '}
        </div>
        <div className={styles.anserLink}>
          <Link to={`/how-it-works#use-the-app-profile`} scroll={smoothScroll}>
            Profile and messages
          </Link>
        </div>
        <div className={styles.anserLink}>
          <Link
            to={`/how-it-works#use-the-app-create-circle`}
            scroll={smoothScroll}
          >
            Create a Circle
          </Link>
        </div>
        <ul>
          <li>
            <Link to={`/how-it-works#info-create-circle`} scroll={smoothScroll}>
              General
            </Link>
          </li>
          <li>
            <Link to={`/how-it-works#info-circle-name`} scroll={smoothScroll}>
              Circle name
            </Link>
          </li>
          <li>
            <Link to={`/how-it-works#info-categories`} scroll={smoothScroll}>
              Categories
            </Link>
          </li>
          <li>
            <Link to={`/how-it-works#info-description`} scroll={smoothScroll}>
              Description
            </Link>
          </li>
          <li>
            <Link to={`/how-it-works#info-video`} scroll={smoothScroll}>
              Video
            </Link>
          </li>
          <li>
            <Link to={`/how-it-works#info-members-info`} scroll={smoothScroll}>
              Members
            </Link>
          </li>
          <li>
            <Link to={`/how-it-works#info-habits`} scroll={smoothScroll}>
              Habits
            </Link>
          </li>
          <li>
            <Link to={`/how-it-works#info-paid-or-free`} scroll={smoothScroll}>
              Paid Subscriptions or Free
            </Link>
          </li>
        </ul>
        <div className={styles.anserLink}>
          <Link
            to={`/how-it-works#use-the-app-create-challenge`}
            scroll={smoothScroll}
          >
            Create a Challenge/Post
          </Link>
        </div>
        <ul>
          <li>
            <Link to={`/how-it-works#info-video-post`} scroll={smoothScroll}>
              Video
            </Link>
          </li>
          <li>
            <Link to={`/how-it-works#info-habits-post`} scroll={smoothScroll}>
              Challenges
            </Link>
          </li>
        </ul>
        <div className={styles.anserLink}>
          <Link to={`/how-it-works#use-the-app-sign-out`} scroll={smoothScroll}>
            Log out
          </Link>
        </div>
        <div className={styles.anserLink}>
          <Link to={`/how-it-works#use-the-app-log-in`} scroll={smoothScroll}>
            Log in
          </Link>
        </div>
        <div className={styles.anserLink}>
          <Link to={`/how-it-works#use-the-app-password`} scroll={smoothScroll}>
            Forgot password
          </Link>
        </div>
        <div className={styles.anserLink}>
          <Link
            to={`/how-it-works#use-the-app-common-issue`}
            scroll={smoothScroll}
          >
            Common issues
          </Link>
        </div>
      </>
    ),
  },
  {
    question: (
      <>
        {' '}
        <Link to={`/how-it-works#support`} scroll={smoothScroll}>
          Contact support
        </Link>
      </>
    ),
  },
];

import {
  IconType,
  WarningBlock,
} from 'src/components/common/HowToPageComponents/WarningBlock/WarningBlock';
import styles from './CreateAnAccount.module.scss';
import { BlockWithImg } from 'src/components/common/HowToPageComponents/BlockWIthImg/BlockWIthImg';
import screenOne from 'src/assets/images/howToPage/Picture3.png';
import screenTwo from 'src/assets/images/howToPage/Picture4.png';
import screenThree from 'src/assets/images/howToPage/Picture5.png';
import screenFour from 'src/assets/images/howToPage/Picture6.png';

export const CreateAnAccount = () => {
  return (
    <div>
      <div className={styles.blockTitle}>2- Create an account</div>
      <div className={styles.description}>
        Now that you have the app downloaded to your device, you can start your
        learning journey by opening the app. <strong>To open the app</strong>,
        tap the Dunbar app icon on your device.{' '}
        <WarningBlock
          title="If you already have an account, please follow the steps in the Sign In section, instead"
          iconType={IconType.Warning}
        />
      </div>
      <div className={styles.content}>
        <BlockWithImg
          title="2.1 Tap “Join Dunbar” to switch from Sign-in to the Join screen."
          src={screenOne}
        />
        <BlockWithImg
          title="2.2 Select your country code and enter your phone number."
          src={screenTwo}
        />
        <BlockWithImg
          title="2.3 Enter a secure password and confirm it."
          src={screenThree}
        />
      </div>
      <div className={styles.bottomContent}>
        <BlockWithImg
          title="2.4 You will receive a code via SMS. Enter it & tap Submit"
          src={screenFour}
        />
        <div>
          <WarningBlock
            title={
              <>
                SMS messages may take several minutes to arrive. If an SMS does
                not arrive, you can tap <strong>Resend Code</strong>
              </>
            }
            iconType={IconType.Warning}
          />
          <WarningBlock
            title={
              <>
                Congratulations! You have successfully created your{' '}
                <strong>account!</strong>
              </>
            }
            iconType={IconType.Positive}
          />
          <WarningBlock
            title="You should immediately proceed to creating your profile."
            iconType={IconType.Warning}
          />
        </div>
      </div>
    </div>
  );
};

import { useEffect } from 'react';
import { SectionComponent } from '../SectionCompnent/SectionCompnent';
import { BIRTHDAY, NAME, PHOTO, BIO, EMAIL } from '../const';
import styles from './InfoProfile.module.scss';

export const InfoProfile = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  return (
    <div className={styles.container}>
      <div id="info-name">
        <SectionComponent
          isSmallTitle
          title={NAME.title}
          description={NAME.description}
        />
      </div>
      <div id="info-photo">
        <SectionComponent
          isSmallTitle
          title={PHOTO.title}
          description={PHOTO.description}
        />
      </div>
      <div id="info-bio">
        <SectionComponent
          isSmallTitle
          title={BIO.title}
          description={BIO.description}
        />
      </div>
      <div id="info-birthday">
        <SectionComponent
          isSmallTitle
          title={BIRTHDAY.title}
          description={BIRTHDAY.description}
        />
      </div>
      <div id="info-email">
        <SectionComponent
          isSmallTitle
          title={EMAIL.title}
          description={EMAIL.description}
        />
      </div>
    </div>
  );
};
